import React from "react"

import { SelectInput } from "react-admin"

export const AvailabilityInput = (props) => {
  return (
    <SelectInput
      {...props}
      choices={[
        { id: "full_time", name: "Full time" },
        { id: "part_time", name: "Part time" },
        { id: "project_by_project", name: "Project by project" },
        { id: "weekends_only", name: "Weekends only" },
        { id: "not_available", name: "Not Available" },
      ]}
    />
  )
}
