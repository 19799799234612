import React from "react"

import moment from "moment"
import {
  DateInput,
  Edit,
  Labeled,
  ReferenceField,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin"
import { makeStyles } from "@mui/styles"

import { TaskStatusInput } from "fields"

const useStyles = makeStyles({
  inlineLeft: {
    display: "inline-flex",
    marginRight: "20px",
    width: "calc(50% - 10px)",
  },
  inlineRight: {
    display: "inline-flex",
    width: "calc(50% - 10px)",
  },
  fullwidth: {
    width: "100%",
  },
})

export const TaskEdit = (props) => {
  const classes = useStyles()
  return (
    <Edit {...props}>
      <SimpleForm>
        <ReferenceField
          fullWidth
          label="Project"
          source="project_id"
          reference="projects"
          sort={{ field: "name", order: "ASC" }}
          filterToQuery={(searchText) => ({ name: searchText })}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextInput
          fullWidth
          source="unique_id"
          label="Unique ID"
          InputProps={{ readOnly: true }}
        />
        <TextInput fullWidth source="name" label="Task Name" />
        <TextInput fullWidth multiline source="description" />
        <TextInput fullWidth multiline source="outline_code" />
        <TextInput fullWidth multiline source="type" label="Type/Category" />
        <TextInput fullWidth source="responsible_party" />
        <TextInput fullWidth source="location" />
        <TaskStatusInput fullWidth source="status" />
        <DateInput
          source="sched_start_date"
          classes={{ root: classes.fullwidth }}
          formClassName={classes.inlineLeft}
          format={(v) => (v ? moment(v).format("YYYY-MM-DD") : null)}
          parse={(v) =>
            v ? moment.utc(v).set("hour", 12).toISOString() : null
          }
        />
        <DateInput
          source="sched_end_date"
          classes={{ root: classes.fullwidth }}
          formClassName={classes.inlineRight}
          format={(v) => (v ? moment(v).format("YYYY-MM-DD") : null)}
          parse={(v) =>
            v ? moment.utc(v).set("hour", 12).toISOString() : null
          }
        />
        <TextInput
          fullWidth
          source="groups[0].group.ext_chat_id"
          label="Group Chat ID"
          InputProps={{ readOnly: true }}
        />
        <Labeled label="Created By">
          <TextField source="created_by" />
        </Labeled>
        <Labeled label="Created Using">
          <TextField fullWidth source="created_using" label="Created Using" />
        </Labeled>
      </SimpleForm>
    </Edit>
  )
}
