import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { get, find } from "lodash"

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import DeleteIcon from "@mui/icons-material/Delete"

import { CompanyWorkerInput } from "./company_worker_input"

import {
  Error,
  Loading,
  useDataProvider,
  useGetOne,
  useRecordContext,
} from "react-admin"

function get_group(record) {
  const groups = get(record, "groups", [])
  if (groups) {
    const tgroup = find(groups, { type: "performer" })
    if (tgroup) {
      return tgroup.group
    }
  }
}

const useStyles = makeStyles({
  inline: {
    display: "inline-flex",
  },
})

export const TaskAssignment = () => {
  const record = useRecordContext()
  const dataProvider = useDataProvider()
  const [group, setGroup] = useState({})
  const [reloading, setReloading] = useState(false)
  const [inputWorkerID, setInputWorkerID] = useState(null)
  const classes = useStyles()

  useEffect(() => {
    setGroup(get_group(record))
  }, [record])

  const projectQuery = useGetOne("projects", {
    id: record && record.project_id,
  })

  if (projectQuery.loading || !projectQuery.data || !group) return <Loading />
  if (reloading) return <Loading />
  if (projectQuery.error) return <Error />

  function onSelect(workerID) {
    setInputWorkerID(workerID)
  }

  function addWorkerToTask(workerID) {
    if (workerID) {
      setReloading(true)
      dataProvider
        .post(`/groups/${group.id}/members`, {
          group_id: group.id,
          member_id: workerID,
          member_role: "owner",
        })
        .then(() => {
          return dataProvider
            .getOne("groups", { id: group.id })
            .then(({ data }) => {
              setGroup(data)
            })
        })
        .finally(() => {
          setReloading(false)
        })
    }
  }

  function removeWorkerFromTask(workerID) {
    if (workerID) {
      setReloading(true)
      // Total hack.  that's not a resource, but it should work.
      dataProvider
        .delete(`groups/${group.id}/members`, { id: workerID })
        .then(() => {
          return dataProvider
            .getOne("groups", { id: group.id })
            .then(({ data }) => {
              setGroup(data)
            })
        })
        .finally(() => {
          setReloading(false)
        })
    }
  }

  const companyID = get(projectQuery, "data.company_id")
  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" key="0" padding="checkbox">
              Delete
            </TableCell>
            <TableCell align="left" key="1">
              Member Name
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {get(group, "members", []).map((member, idx) => {
            return (
              <TableRow key={idx}>
                <TableCell padding="checkbox">
                  <Button
                    onClick={() => removeWorkerFromTask(member.member_id)}
                  >
                    <DeleteIcon color="primary" />
                  </Button>
                </TableCell>
                <TableCell>{member.member_name}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <form style={{ display: "flex", padding: "20px" }}>
        <CompanyWorkerInput
          record={{ cwid: null }}
          source="cwid"
          company_id={companyID}
          onSelectWorkerID={onSelect}
          className={classes.inline}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={() => addWorkerToTask(inputWorkerID)}
          disabled={!inputWorkerID}
          className={classes.inline}
        >
          Add
        </Button>
      </form>
    </>
  )
}

TaskAssignment.propTypes = {
  record: PropTypes.object,
}
