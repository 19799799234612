import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import _ from "lodash"

import { useInput } from "react-admin"

const basicBenefits = [
  { id: "medical", name: "medical" },
  { id: "dental", name: "dental" },
  { id: "vision", name: "vision" },
  { id: "FSA", name: "FSA" },
  { id: "401(k)", name: "401(k)" },
  { id: "529", name: "529" },
  { id: "life insurance", name: "life insurance" },
  { id: "disability coverage", name: "disability coverage" },
  { id: "vacations", name: "vacations" },
  { id: "sick leave", name: "sick leave" },
  { id: "cell phone", name: "cell phone" },
  { id: "company vehicle", name: "company vehicle" },
  { id: "parental leave", name: "parental leave" },
  { id: "credit union membership", name: "credit union membership" },
  { id: "gym subsidy", name: "gym subsidy" },
]

// eslint-disable-next-line no-unused-vars
export const BenefitsInput = ({ basePath, ...props }) => {
  const [options, setOptions] = useState(basicBenefits)
  const [value, setValue] = React.useState([])

  const benefits = props.record && props.record.benefits
  useEffect(() => {
    let initialOpts = options
    let initialValues = []

    const nonNullBenefits = benefits || []
    // Assure value is in options and prep selected objects
    for (const v of nonNullBenefits) {
      let opt = _.find(options, { id: v })
      if (!opt) {
        opt = { id: v, name: v }
        initialOpts = initialOpts.concat([opt])
      }
      initialValues.push(opt)
    }
    if (initialOpts.length > options.length) {
      setOptions(initialOpts)
    }
    setValue(initialValues)
  }, [benefits])

  const {
    field: { onChange },
    fieldState: { isTouched, error },
  } = useInput(props)

  function filterChoices(options, params) {
    if (params && params.inputValue) {
      const filterLower = params.inputValue.toLowerCase()
      let matches = basicBenefits.filter(
        (choice) => choice.name.toLowerCase().indexOf(filterLower) >= 0
      )
      for (let match of matches) {
        if (match.name.toLowerCase() === filterLower) {
          return matches
        }
      }
      return [{ id: params.inputValue, name: params.inputValue }, ...matches]
    }
    return basicBenefits
  }

  return (
    <Autocomplete
      {...props}
      multiple
      name="benefits_input"
      getOptionLabel={(option) => option.name}
      value={value}
      variant="standard"
      error={isTouched && error ? error : undefined}
      options={options}
      filterOptions={filterChoices}
      onChange={(event, newValue) => {
        for (const val of newValue) {
          let present = options.filter((opt) => opt.id === val.id)
          if (present.length === 0) {
            setOptions([val, ...options])
          }
        }
        setValue(newValue)
        // Form/db wants array of strings.  Maybe eventually use parse/format
        // to fix this one
        onChange(newValue.map((v) => v.id))
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label="Benefits"
          placeholder="Favorites"
        />
      )}
      style={{ marginBottom: "1.5rem" }}
    />
  )
}

BenefitsInput.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
  basePath: PropTypes.string,
}
