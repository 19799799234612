import React from "react"
import { useInput } from "react-admin"

import { AutocompleteArrayInput } from "react-admin"

export const ConstructionTypesInput = (props) => {
  const {
    field: { onChange },
  } = useInput(props)

  return (
    <AutocompleteArrayInput
      {...props}
      onChange={(val) => {
        if (val.length > 1 && val.indexOf("all") >= 0) {
          onChange(val.filter((v) => v !== "all"))
        } else if (val.length === 0) {
          onChange(["all"])
        }
      }}
      choices={[
        { id: "all", name: "All types" },
        { id: "commerical", name: "Commerical" },
        { id: "residential", name: "Residential" },
        { id: "industrial", name: "Industrial" },
        { id: "education", name: "Education" },
        { id: "office", name: "Work / Office" },
        { id: "mixed-use", name: "Mixed-use" },
        { id: "healthcare", name: "Health-care / Medical / Hospitals" },
      ]}
    />
  )
}
