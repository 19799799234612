import React from "react"

import { SelectInput } from "react-admin"

export const ApplicationStatusInput = (props) => (
  <SelectInput
    {...props}
    label="App Status"
    source="application_status"
    parse={(value) => value}
    choices={[
      { id: "suggested", name: "Suggested" },
      { id: "viewed", name: "Viewed" },
      { id: "saved", name: "Saved" },
      { id: "applied", name: "Applied" },
      { id: "withdraw", name: "Withdraw" },
      { id: "company_declined", name: "Company Declined" },
      { id: "invited", name: "Invited" },
      { id: "interviewing", name: "Interviewing" },
      { id: "offer", name: "Offer" },
      { id: "accepted_offer", name: "Accepted Offer" },
      { id: "declined_offer", name: "Declined Offer" },
      { id: "hired", name: "Hired" },
      { id: "job_canceled", name: "Job Canceled" },
    ]}
  />
)
