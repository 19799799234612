import React from "react"
import PropTypes from "prop-types"

import {
  ArrayInput,
  AutocompleteInput,
  SimpleFormIterator,
  required,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  FormDataConsumer,
  List,
  ReferenceInput,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  SelectInput,
  useRecordContext,
} from "react-admin"

import { makeStyles } from "@mui/styles"
import Config from "./config"
import { CustomListActions } from "./list_actions"
import { ProjectInput } from "fields"

const useStyles = makeStyles({
  halfWidth: {
    width: "40%",
    marginRight: "1rem",
  },
})

const CompanyWorkerFilter = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput
        fullWidth
        label="Company"
        source="company_id"
        reference="companies"
        sort={{ field: "company_name", order: "ASC" }}
        filterToQuery={(searchText) => ({ q: searchText })}
        alwaysOn
      >
        <AutocompleteInput optionText="company_name" source="company_name" />
      </ReferenceInput>
      <ReferenceInput
        fullWidth
        label="Worker"
        source="worker_id"
        reference="workers"
        sort={{ field: "full_name", order: "ASC" }}
        filterToQuery={(searchText) => ({ q: searchText })}
        filter={{ status: ["open", "invited"] }}
        alwaysOn
      >
        <AutocompleteInput
          optionText={(r) =>
            r && r.id
              ? `${r.full_name} (${r.mobile_number || r.email}) ${
                  r.trade || "empty trade"
                }`
              : ""
          }
          _source="full_name"
        />
      </ReferenceInput>
      <SelectInput
        label="Status"
        source="status"
        alwaysOn
        choices={[
          { id: "invited", name: "Invited" },
          { id: "active", name: "Active" },
          { id: "removed", name: "Removed" },
        ]}
      />
    </Filter>
  )
}

const WorkerCardField = ({ source }) => {
  const record = useRecordContext()
  const name = record[source]
  return (
    <a
      href={`${Config.jbURL}/workercard/${record[source]}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {name}
    </a>
  )
}

WorkerCardField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
}

const JobFlyerField = ({ source }) => {
  const record = useRecordContext()
  const name = record[source]
  return (
    <a
      href={`${Config.jbURL}/jobflyer/${record[source]}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {name}
    </a>
  )
}

JobFlyerField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
}

export const CompanyWorkerList = ({ hasEdit, hasList, ...props }) => (
  <List
    {...props}
    hasEdit={hasEdit}
    hasList={hasList}
    filters={<CompanyWorkerFilter />}
    actions={<CustomListActions {...props} hasCreate={true} />}
    filterDefaultValues={{ status: "active" }}
  >
    <Datagrid>
      <WorkerCardField label="card" source="worker_id" sortable={false} />
      <TextField
        label="first"
        source="workercard.first_name"
        sortable={false}
      />
      <TextField label="last" source="workercard.last_name" sortable={false} />
      <TextField label="Company" source="company_name" />
      <DateField source="time_created" showTime />
      <TextField source="status" />
      <TextField source="relation" />
      <TextField source="roles" />
      <TextField source="orgs" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

CompanyWorkerList.propTypes = {
  hasEdit: PropTypes.bool,
  hasList: PropTypes.bool,
}

const CompanyWorkerForm = (props) => {
  const classes = useStyles()
  return (
    <SimpleForm {...props}>
      <ReferenceInput
        fullWidth
        label="Worker"
        source="worker_id"
        reference="workers"
        sort={{ field: "full_name", order: "ASC" }}
        filterToQuery={(searchText) => ({
          q: searchText,
          status: ["open", "invited"],
        })}
        allowEmpty={true}
        validate={required()}
      >
        <AutocompleteInput
          classes={{ root: classes.halfWidth }}
          optionText={(r) => {
            return r && r.id
              ? `${r.full_name} (${r.mobile_number}) ${
                  r.trade || "empty trade"
                }`
              : ""
          }}
          source="full_name"
        />
      </ReferenceInput>
      <ReferenceInput
        fullWidth
        label="Company"
        source="company_id"
        reference="companies"
        sort={{ field: "company_name", order: "ASC" }}
        filterToQuery={(searchText) => ({ q: searchText })}
        allowEmpty={true}
        validate={required()}
      >
        <AutocompleteInput
          className={classes.halfWidth}
          optionText="company_name"
          source="company_name"
        />
      </ReferenceInput>

      <FormDataConsumer>
        {({ formData }) => (
          <ProjectInput source="project_id" company_id={formData.company_id} />
        )}
      </FormDataConsumer>

      <SelectInput
        className={classes.halfWidth}
        label="Status"
        source="status"
        parse={(value) => value}
        choices={[
          { id: "active", name: "active" },
          { id: "invited", name: "invited" },
          { id: "removed", name: "removed" },
        ]}
      />

      <SelectInput
        className={classes.halfWidth}
        label="Relation"
        source="relation"
        parse={(value) => value}
        choices={[
          { id: "employee", name: "Employee" },
          { id: "contract", name: "Contract" },
          { id: "core", name: "Core" },
        ]}
      />

      <ArrayInput source="roles" validate={required()}>
        <SimpleFormIterator>
          <SelectInput
            className={classes.halfWidth}
            label="Roles"
            choices={[
              { id: "company_admin", name: "Company Admin" },
              { id: "project_admin", name: "Project Admin" },
              { id: "worker", name: "Worker (default)" },
              { id: "foreman", name: "Foreman" },
            ]}
          />
        </SimpleFormIterator>
      </ArrayInput>

      <ArrayInput source="orgs">
        <SimpleFormIterator>
          <TextInput className={classes.halfWidth} />
        </SimpleFormIterator>
      </ArrayInput>

      <TextInput
        fullWidth
        label="notes"
        source="notes"
        parse={(value) => value}
      />
    </SimpleForm>
  )
}

const preventNull = (record) => {
  if (!record.worker_id) {
    record = {
      ...record,
      worker_id: [],
    }
  }
  if (!record.job_id) {
    record = {
      ...record,
      job_id: [],
    }
  }
  if (!record.application_status) {
    record = {
      ...record,
      application_status: [],
    }
  }
  return record
}

export const CompanyWorkerCreate = (props) => (
  <Create {...props} transform={preventNull}>
    <CompanyWorkerForm {...props} />
  </Create>
)

export const CompanyWorkerEdit = (props) => (
  <Edit {...props} transform={preventNull}>
    <CompanyWorkerForm {...props} />
  </Edit>
)

export const CompanyWorkerShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Basic">
        <TextField label="ID" source="worker_id" sortable={false} />
        <TextField
          label="first"
          source="workercard.first_name"
          sortable={false}
        />
        <TextField
          label="last"
          source="workercard.last_name"
          sortable={false}
        />
        <TextField source="id" />
        <TextField source="company_name" />
        <DateField source="time_created" showTime />
        <TextField source="status" />
        <TextField source="relation" />
        <TextField source="roles" />
        <TextField source="orgs" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)
