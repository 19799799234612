import React from "react"
import { Login, LoginForm } from "react-admin"
import ForgotPasswordButton from "./forgot_password"

const CustomLoginForm = (props) => (
  <div>
    <LoginForm {...props} />
  </div>
)

const CustomLoginPage = (props) => (
  <Login {...props}>
    <CustomLoginForm {...props} />
    <ForgotPasswordButton {...props} />
  </Login>
)

export default CustomLoginPage
