import React, { Fragment, useState, useEffect } from "react"
import PropTypes from "prop-types"

import MapboxClient from "@mapbox/mapbox-sdk"
import mbxGeocoding from "@mapbox/mapbox-sdk/services/geocoding"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"

import { useInput } from "react-admin"

const baseClient = MapboxClient({
  accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
})
const geoClient = mbxGeocoding(baseClient)

const mapboxQueryOptions = {
  countries: ["us"],
  mode: "mapbox.places-permanent",
  limit: 10,
  types: ["place", "postcode"],
}

export const AddressInput = ({ source, label, ...props }) => {
  const {
    field,
    fieldState: { isTouched, error },
    isRequired,
  } = useInput({ ...props, source, label })

  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const [options, setOptions] = useState([])
  const loading = open && options.length === 0

  useEffect(() => {
    if (field.value) {
      setInputValue(field.value)
      const v = { id: field.value, name: field.value }
      setOptions([v])
    } else {
      setInputValue("")
    }
  }, [field.value])

  useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  useEffect(() => {
    let active = true

    if (inputValue === "") {
      setOptions(field.value ? [{ id: field.value, name: field.value }] : [])
      return
    }

    if (inputValue.length < 3) {
      return
    }

    geoClient
      .forwardGeocode({ ...mapboxQueryOptions, query: inputValue })
      .send()
      .then((res) => {
        if (active) {
          setOptions(
            res.body.features.map((place) => {
              return {
                id: place.place_name,
                name: place.place_name,
              }
            })
          )
        }
      })

    return () => {
      active = false
    }
  }, [inputValue])

  const textLabel = `${label}${isRequired ? " *" : ""}`

  return (
    <Fragment>
      <Autocomplete
        {...props}
        error={isTouched && error ? error : undefined}
        getOptionLabel={(option) => option.name || ""}
        getOptionSelected={(option, value) => option.name === value.name}
        loading={loading}
        name="address_input"
        options={options}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={field.value ? { id: field.value, name: field.value } : null}
        inputValue={inputValue}
        onChange={(event, newValue) => {
          if (newValue) {
            field.onChange(newValue.name)
          } else {
            field.onChange("")
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        variant="standard"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            error={!!(isTouched && error)}
            label={textLabel}
            placeholder="address"
          />
        )}
        style={{ marginBottom: ".5rem" }}
      />
      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
        {isTouched && error}&nbsp;
      </p>
    </Fragment>
  )
}

AddressInput.propTypes = {
  source: PropTypes.string,
  label: PropTypes.string,
}
