import React from "react"

import { SelectInput } from "react-admin"

export const YearsOfXPInput = (props) => {
  return (
    <SelectInput
      {...props}
      choices={[
        { id: "0-1", name: "0-1" },
        { id: "2-4", name: "2-4" },
        { id: "5-10", name: "5-10" },
        { id: "11-15", name: "11-15" },
        { id: "16-20", name: "16-20" },
        { id: "20+", name: "20+" },
      ]}
    />
  )
}
