import React from "react"
import { Route } from "react-router-dom"

import "./App.css"
import { Admin, CustomRoutes, Resource } from "react-admin"

import { CertificationList, CertificationEdit } from "./certifications"
import {
  CompanyList,
  CompanyCreate,
  CompanyEdit,
  CompanyShow,
} from "./companies"
import { DemandLeadList, DemandLeadEdit } from "./demand_leads"
import { JobEdit, JobList, JobCreate, JobMassCreate, JobShow } from "./jobs"
import { TaskCreate, TaskEdit, TaskList, TaskShow } from "./tasks"
import { TestIDCreate, TestIDList } from "./test_id"
import { WorkerList, WorkerCreate, WorkerEdit, WorkerShow } from "./workers"
import { UtilityView } from "./utilities"

import {
  CompanyWorkerList,
  CompanyWorkerCreate,
  CompanyWorkerEdit,
  CompanyWorkerShow,
} from "company_workers"

import { dataProvider } from "data_provider"

import { LoginGrant } from "login_grant"

import {
  CampaignList,
  CampaignCreate,
  CampaignEdit,
  CampaignShow,
} from "campaigns"
import { authProvider } from "./firebase"
import CustomLoginPage from "custom_login"
import { SkillList, SkillCreate, SkillEdit } from "./skills"
import {
  JobApplicationList,
  JobApplicationCreate,
  JobApplicationEdit,
  JobApplicationShow,
} from "job_applications"
import { ProjectCreate, ProjectEdit, ProjectList } from "projects"
import { ReportsView } from "reports"
import { UnionList, UnionCreate } from "unions"

const addlRoutes = []

function App() {
  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      customRoutes={addlRoutes}
      loginPage={CustomLoginPage}
    >
      <Resource
        key="resource_workers"
        name="workers"
        list={WorkerList}
        edit={WorkerEdit}
        create={WorkerCreate}
        show={WorkerShow}
      />
      ,
      <Resource
        key="resource_companies"
        name="companies"
        list={CompanyList}
        edit={CompanyEdit}
        create={CompanyCreate}
        show={CompanyShow}
      />
      ,
      <Resource
        key="resource_jobs"
        name="jobs"
        list={JobList}
        create={JobCreate}
        show={JobShow}
        edit={JobEdit}
      />
      ,
      <Resource
        key="resource_skills"
        name="approvedskills"
        options={{ label: "Skills" }}
        list={SkillList}
        create={SkillCreate}
        edit={SkillEdit}
      />
      ,
      <Resource
        key="resource_certifications"
        name="certifications"
        options={{ label: "Certifications" }}
        list={CertificationList}
        edit={CertificationEdit}
      />
      ,
      <Resource
        key="resource_jobapps"
        name="jobapplications"
        options={{ label: "Job Applications" }}
        list={JobApplicationList}
        edit={JobApplicationEdit}
        create={JobApplicationCreate}
        show={JobApplicationShow}
      />
      ,
      <Resource
        key="resource_companyworkers"
        name="companyworkers"
        options={{ label: "Company Workers" }}
        list={CompanyWorkerList}
        edit={CompanyWorkerEdit}
        create={CompanyWorkerCreate}
        show={CompanyWorkerShow}
      />
      ,
      <Resource
        key="resource_campaigns"
        name="campaigns"
        options={{ label: "Campaigns" }}
        list={CampaignList}
        edit={CampaignEdit}
        create={CampaignCreate}
        show={CampaignShow}
      />
      ,
      <Resource
        key="resource_demandleads"
        name="demandleads"
        options={{ label: "Demand Side Leads" }}
        list={DemandLeadList}
        edit={DemandLeadEdit}
      />
      ,
      <Resource
        key="projects"
        name="projects"
        options={{ label: "Projects" }}
        create={ProjectCreate}
        list={ProjectList}
        edit={ProjectEdit}
      />
      ,
      <Resource
        key="tasks"
        name="tasks"
        options={{ label: "Tasks" }}
        list={TaskList}
        create={TaskCreate}
        edit={TaskEdit}
        show={TaskShow}
      />
      ,
      <Resource
        key="logins"
        name="logins"
        options={{ label: "Logins" }}
        list={LoginGrant}
      />
      ,
      <Resource
        key="reports"
        name="reports"
        options={{ label: "Reports" }}
        list={ReportsView}
      />
      ,
      <Resource
        key="testids"
        name="testids"
        options={{ label: "Test IDs" }}
        list={TestIDList}
        create={TestIDCreate}
      />
      ,
      <Resource
        key="unions"
        name="unions"
        options={{ label: "Unions" }}
        list={UnionList}
        create={UnionCreate}
      />
      ,
      <Resource
        key="utilities"
        name="utilities"
        options={{ label: "Utilities" }}
        list={UtilityView}
      />
      ,
      <Resource key="audits" name="audits" />,
      <Resource key="groups" name="groups" />,
      <Resource key="jobmatches" name="jobmatches" />,
      <Resource key="reportresults" name="reportresults" />,
      <CustomRoutes>
        <Route key="route1" path="/jobs/masscreate" component={JobMassCreate} />
        ,
      </CustomRoutes>
    </Admin>
  )
}

export default App
