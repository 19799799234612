import React from "react"
import PropTypes from "prop-types"

import {
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  Filter,
  List,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin"
import { CustomListActions } from "./list_actions"

const DemandLeadFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Status" source="status" alwaysOn />
  </Filter>
)

export const DemandLeadList = ({ hasEdit, hasList, ...props }) => (
  <List
    {...props}
    hasList={hasList}
    hasEdit={hasEdit}
    actions={<CustomListActions {...props} maxResults={10000} />}
    filterDefaultValues={{ status: "created" }}
    filters={<DemandLeadFilter />}
  >
    <Datagrid>
      <TextField label="company_name" source="company_name" />
      <TextField label="contact_name" source="company_contact.name" />
      <TextField label="contact_phone" source="company_contact.phone_number" />
      <DateField source="time_created" showTime />
      <TextField label="status" source="status" />

      <EditButton />
    </Datagrid>
  </List>
)

DemandLeadList.propTypes = {
  hasEdit: PropTypes.bool,
  hasList: PropTypes.bool,
}

export const DemandLeadEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput disabled source="id" />
        <DateField source="time_created" showTime />
        <TextInput source="company_name" />
        <TextInput source="company_contact.name" />
        <TextInput source="company_contact.phone_number" />
        <TextInput source="company_contact.email" />
        <TextInput source="company_url" />
        <TextInput fullWidth multiline source="pitch" />
        <SelectInput
          source="job_count"
          choices={[
            { id: "1", name: "1" },
            { id: "2-3", name: "2-3" },
            { id: "4-5", name: "4-5" },
            { id: "6-10", name: "6-10" },
            { id: "11+", name: "11+" },
          ]}
        />
        <TextInput fullWidth multiline source="job_location" />
        <TextInput fullWidth multiline source="job_description" />
        <TextInput fullWidth multiline source="job_skills" />
        <DateInput source="job_start_date" />
        <SelectInput
          source="job_availability"
          choices={[
            { id: "full-time", name: "Full Time" },
            { id: "part-time", name: "Part Time" },
            { id: "temporary", name: "Temporary" },
          ]}
        />
        <TextInput fullWidth source="job_salary" />
        <TextInput source="bid_amount.amount" />
        <SelectInput
          source="bid_amount.currency"
          choices={[
            { id: "USD", name: "USD" },
            { id: "JPY", name: "JPY" },
          ]}
        />
        <TextInput fullWidth multiline source="notes" />
        <SelectInput
          source="status"
          choices={[
            { id: "created", name: "Created" },
            { id: "qualified", name: "Qualified" },
            { id: "called", name: "Called" },
            { id: "negotiated", name: "Negotiated" },
            { id: "proposal", name: "Deal/Proposal" },
            { id: "active", name: "Active" },
            { id: "rejected", name: "Rejected" },
          ]}
        />
      </SimpleForm>
    </Edit>
  )
}
