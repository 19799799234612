import React, { Fragment } from "react"
import PropTypes from "prop-types"

import {
  ArrayInput,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  ImageField,
  ImageInput,
  List,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleFormIterator,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  required,
  useShowController,
} from "react-admin"

import { makeStyles } from "@mui/styles"

import { CompanyApplicantList } from "company_applicant_list"
import { CustomListActions } from "list_actions"
import { JobList } from "jobs/job_list"
import { dataProvider } from "data_provider"

const useStyles = makeStyles({
  inline: {
    display: "inline-flex",
    marginRight: "1rem",
  },
})

const CompanyFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Keyword" source="q" alwaysOn />
  </Filter>
)

const ImageList = ({ source, record }) => {
  return (
    <Fragment>
      {record && record[source] && record[source].length > 0
        ? record[source].map((img, idx) => (
            <img
              alt="Project Image"
              key={`carousel-${idx}`}
              src={img}
              style={{
                width: "120px",
                display: "inline-flex",
                margin: "1rem",
              }}
            />
          ))
        : null}
    </Fragment>
  )
}
ImageList.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
}

export const CompanyList = ({ hasEdit, hasList, ...props }) => {
  return (
    <List
      {...props}
      hasEdit={hasEdit}
      hasList={hasList}
      filters={<CompanyFilter />}
      actions={<CustomListActions {...props} hasCreate={true} />}
    >
      <Datagrid>
        <TextField source="company_name" />
        <TextField source="company_address" />
        <DateField source="time_created" showTime />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  )
}

CompanyList.propTypes = {
  hasEdit: PropTypes.bool,
  hasList: PropTypes.bool,
}

const CompanyForm = ({
  hasList, // eslint-disable-line no-unused-vars
  hasEdit, // eslint-disable-line no-unused-vars
  hasShow, // eslint-disable-line no-unused-vars
  hasCreate, // eslint-disable-line no-unused-vars
  hasImageUpload,
  ...props
}) => {
  const classes = useStyles()
  return (
    <SimpleForm
      {...props}
      defaultValues={{ project_images: [], preferred_hiring_sources: [] }}
    >
      <TextInput source="company_name" fullWidth validate={required()} />
      <SelectInput
        source="type"
        choices={[
          { id: "company", name: "Company" },
          { id: "coreslight", name: "CoresLight" },
        ]}
      />
      <SelectInput
        source="status"
        choices={[
          { id: "active", name: "Active" },
          { id: "unclaimed", name: "Unclaimed" },
          { id: "disabled", name: "Disabled" },
        ]}
      />
      <TextInput source="company_address" fullWidth parse={(value) => value} />

      <TextInput source="city" fullWidth />
      <TextInput source="state" formClassName={classes.inline} />
      <TextInput source="country" formClassName={classes.inline} />

      <TextInput source="business_url" fullWidth parse={(value) => value} />
      {hasImageUpload ? (
        <ImageInput
          source="logo_url"
          format={(logo) => {
            return typeof logo === "string" ? { src: logo } : logo
          }}
        >
          <ImageField source="src" />
        </ImageInput>
      ) : null}
      <TextInput
        source="banner_text"
        label="Banner text (multiline)"
        fullWidth
        multiline
        parse={(value) => value}
      />
      <TextInput
        source="pitch_text"
        label="Pitch text (multiline)"
        fullWidth
        multiline
        parse={(value) => value}
      />
      <TextInput
        source="alias"
        label="URL Alias"
        fullWidth
        parse={(value) => value}
      />
      <SelectInput
        source="language_pref"
        choices={[
          { id: "en_US", name: "English (US)" },
          { id: "pt_BR", name: "Portuguese (BR)" },
          { id: "es_US", name: "Spanish (US)" },
          { id: "ja_JP", name: "Japanese (JP)" },
        ]}
      />
      <SelectInput
        source="locale"
        choices={[
          { id: "US", name: "US" },
          { id: "BR", name: "BR" },
        ]}
      />
      {hasImageUpload ? (
        <ImageInput
          multiple
          source="project_images"
          format={(arr) => {
            return arr.map((url, idx) => {
              if (typeof url === "string") {
                return { unique_id: idx, src: url }
              } else {
                return url
              }
            })
          }}
        >
          <ImageField source="src" title="filename" />
        </ImageInput>
      ) : null}
      <ArrayInput source="preferred_hiring_sources">
        <SimpleFormIterator>
          <TextInput
            source="name"
            label="Name"
            fullWidth
            validate={required()}
          />
          <TextInput
            source="address"
            label="Address"
            fullWidth
            validate={required()}
          />
        </SimpleFormIterator>
      </ArrayInput>
      <h3>Company Settings</h3>
      <SelectInput
        label="Actual Task Date Style"
        source="company_settings.actual_date_style"
        choices={[
          { id: "on_green", name: "On Green" },
          { id: "on_approved", name: "On Approved" },
        ]}
      />
      <BooleanInput
        label="Limit Org View"
        source="company_settings.limit_org_view"
      />
      <BooleanInput
        label="Create Activity Chat Rooms"
        source="company_settings.task_room_creation"
      />
    </SimpleForm>
  )
}

CompanyForm.propTypes = {
  hasList: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasShow: PropTypes.bool,
  hasCreate: PropTypes.bool,
  hasImageUpload: PropTypes.bool,
}

const onSave = async (record) => {
  if (!record.preferred_hiring_sources) {
    record = {
      ...record,
      preferred_hiring_sources: [],
    }
  }
  if (!record.project_images) {
    record = {
      ...record,
      project_images: [],
    }
  }

  if (record.project_images) {
    record = {
      ...record,
      project_images: await dataProvider.uploadProjectImages(
        record.id,
        record.project_images
      ),
    }
  }
  if (record.logo_url) {
    record = {
      ...record,
      logo_url: await dataProvider.uploadLogoImage(record.id, record.logo_url),
    }
  }

  return record
}

export const CompanyCreate = (props) => (
  <Create {...props} transform={onSave}>
    <CompanyForm {...props} hasImageUpload={false} />
  </Create>
)

export const CompanyEdit = (props) => (
  <Edit {...props} transform={onSave}>
    <CompanyForm {...props} hasImageUpload />
  </Edit>
)

export const HeadcountField = (props) => (
  <span>
    `${props.record.filled || 0} / ${props.record.headcount}`
  </span>
)
HeadcountField.propTypes = {
  record: PropTypes.object,
}

const CompanyJobList = (props) => {
  const { record } = useShowController()
  return (
    <JobList
      {...props}
      hasEdit={false}
      hasList={false}
      hasShow={false}
      hasCreate={false}
      resource="jobs"
      company_id={record.id}
      filter={{ company_id: record && record.id }}
    />
  )
}
CompanyJobList.propTypes = {
  record: PropTypes.object,
}

const CompanyApplicationList = (props) => {
  return (
    <CompanyApplicantList
      {...props}
      hasEdit={false}
      hasList={false}
      hasShow={false}
      hasCreate={false}
      resource="jobapplications"
      filter={{ company_id: props.record.id }}
    />
  )
}
CompanyApplicationList.propTypes = {
  record: PropTypes.object,
}

export const CompanyShow = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <ImageField source="logo_url" title="Company Logo" />
          <TextField source="company_name" fullWidth />
          <TextField source="company_address" fullWidth />
          <TextField source="business_url" fullWidth />
          <TextField
            source="banner_text"
            label="Banner text (multiline)"
            fullWidth
          />
          <TextField
            source="pitch_text"
            label="Pitch text (multiline)"
            fullWidth
          />
          <TextField source="alias" label="URL Alias" fullWidth />
          <ImageList source="project_images" />
        </Tab>
        <Tab label="Jobs">
          <CompanyJobList {...props} />
        </Tab>
        <Tab label="Applicants">
          <CompanyApplicationList />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

CompanyShow.propTypes = {
  record: PropTypes.object,
}
