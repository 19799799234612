import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { useInput, useGetList, Loading, Error } from "react-admin"
import Autocomplete from "@mui/material/Autocomplete"
import Chip from "@mui/material/Chip"
import TextField from "@mui/material/TextField"
import { useFormState } from "react-hook-form"
import { omit } from "lodash"

import { dataProvider } from "../data_provider"

export const WorkerSkillsInput = ({
  source,
  multiple,
  filter = {},
  soft_skills,
  classes,
  formClassName,
  ...props
}) => {
  props = omit(props, ["basePath"])
  const [options, setOptions] = useState([])
  const {
    field: { value, onChange },
  } = useInput({ ...props, source })
  const query = useGetList("approvedskills", {
    pagination: { page: 1, perPage: 500 },
    sort: { field: "category", order: "ASC" },
    filter: filter,
  })

  console.log(`skills input from value ${value} at ${source}`)

  // initialize options with all skills
  useEffect(() => {
    let skills = query.data
    if (skills) {
      if (soft_skills === true) {
        skills = skills.filter((opt) => opt.category === "softskills")
      } else if (soft_skills === false) {
        skills = skills.filter((opt) => opt.category !== "softskills")
      }
      skills = skills.map((opt) => {
        const { category, description, tag, type, status, ...rest } = opt // eslint-disable-line no-unused-vars
        return {
          category,
          description,
          tag,
          type,
          review_status: "approved",
          status,
        }
      })
      setOptions(skills)
    } else if (value) {
      setOptions(value)
    }
  }, [value, query.data])

  if (query.isLoading) return <Loading />
  if (query.error) return <Error error={query.error} />

  function filterOptions(options, params) {
    // Remove already-selected skills
    if (options instanceof Array) {
      options = options.filter((option) => {
        if (value) {
          const vArr = value instanceof Array ? value : [value]
          for (let v of vArr) {
            if (option.category === v.category && option.tag === v.tag) {
              return false
            }
          }
        }
        return true
      })
    }
    if (params && params.inputValue) {
      const filterLower = params.inputValue.toLowerCase()
      return options.filter((option) => {
        return (
          (option.tag && option.tag.toLowerCase().indexOf(filterLower)) >= 0 ||
          (options.category &&
            option.category.toLowerCase().indexOf(filterLower)) >= 0 ||
          (option.description &&
            option.description.toLowerCase().indexOf(filterLower) >= 0)
        )
      })
    }
    return options
  }

  let defaultedValue = value
  if (multiple && !value) {
    defaultedValue = []
  }

  return (
    <Autocomplete
      {...props}
      getOptionLabel={(option) => option && option.tag}
      options={options}
      value={defaultedValue}
      variant="filled"
      multiple={multiple}
      onChange={(event, newValue) => {
        onChange(newValue)
      }}
      isOptionEqualToValue={(a, b) => {
        return (
          a.category.toLowerCase() === b.category.toLowerCase() &&
          a.tag === b.tag
        )
      }}
      className={formClassName}
      classes={classes}
      filterOptions={filterOptions}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          let styles = {}
          if (option) {
            if (
              (option.pending_verifiers && option.pending_verifiers.length) ||
              (option.unused_verifiers && option.unused_verifiers.length) ||
              (option.completed_verifiers && option.completed_verifiers.length)
            ) {
              styles.backgroundColor = "#c0c0c0"
            }
          }
          return (
            <Chip
              key={`chip-${index}`}
              label={option.tag}
              {...getTagProps({ index })}
              style={styles}
            />
          )
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label="Skills"
          placeholder="Skills"
        />
      )}
    />
  )
}

WorkerSkillsInput.propTypes = {
  classes: PropTypes.object,
  filter: PropTypes.object,
  formClassName: PropTypes.string,
  multiple: PropTypes.bool,
  soft_skills: PropTypes.bool,
  source: PropTypes.string,
}

export const AutoAssignWorkerSkillsInput = ({ trade, ...props }) => {
  const {
    input: { value },
  } = useInput(props)
  const form = useFormState()

  useEffect(() => {
    if (trade) {
      dataProvider
        .getList("approvedskills", {
          filter: {
            type: "trade",
            category: trade,
            action: "auto_assign",
          },
          pagination: { page: 1, perPage: 100 },
          sort: ["id", "ASC"],
        })
        .then(({ data }) => {
          data = data.map((opt) => {
            const { category, description, tag, type, status, ...rest } = opt // eslint-disable-line no-unused-vars
            return {
              category,
              description,
              tag,
              type,
              review_status: "approved",
              status,
            }
          })
          const nextSkills = value.slice()
          data.forEach((addedSkill) => {
            let skill
            for (skill of nextSkills) {
              if (
                addedSkill.type === skill.type &&
                addedSkill.tag === skill.tag &&
                addedSkill.category === skill.category
              ) {
                return
              }
            }
            nextSkills.push(addedSkill)
          })
          form.change(props.source, nextSkills)
        })
    }
  }, [trade]) // eslint-disable-line

  return <WorkerSkillsInput {...props} />
}
AutoAssignWorkerSkillsInput.propTypes = {
  trade: PropTypes.string,
  source: PropTypes.string,
}
