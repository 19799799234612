import React from "react"
import PropTypes from "prop-types"

import {
  List,
  Datagrid,
  DateField,
  BooleanField,
  TextField,
  useShowContext,
} from "react-admin"

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material"

import { get, isEqual } from "lodash"

export const AuditList = ({ source_table, hide_fields, ...props }) => {
  const { record } = useShowContext()
  return (
    <List
      {...props}
      hasCreate={false}
      hasEdit={false}
      hasList={false}
      hasShow={false}
      resource="audits"
      filter={{ source_table: source_table, source_id: record.id }}
    >
      <Datagrid
        {...props}
        expand={
          <ObjectDiff
            source_a="before"
            source_b="after"
            hide_fields={hide_fields}
          />
        }
      >
        <DateField showTime source="time_created" />
        <BooleanField source="deleted" />
        <TextField source="actor_identifier" />
        <TextField source="actor_platform" />
      </Datagrid>
    </List>
  )
}

AuditList.propTypes = {
  source_table: PropTypes.string.isRequired,
  hide_fields: PropTypes.arrayOf(PropTypes.string),
}

function getValue(value) {
  if (typeof value == "object") {
    return JSON.stringify(value)
  }
  if (value) {
    return value
  }
  return "Empty"
}

const ObjectDiff = ({ hide_fields = [], ...props }) => {
  const a = JSON.parse(get(props, ["record", props.source_a], "{}"))
  const b = JSON.parse(get(props, ["record", props.source_b], "{}"))

  const merged = { ...a, ...b }
  const diffs = []
  for (let key in merged) {
    if (hide_fields.indexOf(key) < 0 && !isEqual(a[key], b[key])) {
      diffs.push(key)
    }
  }

  if (diffs.length == 0) {
    return <h3>No differences found</h3>
  }
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Field</TableCell>
            <TableCell>Before</TableCell>
            <TableCell>After</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {diffs.map((field, idx) => {
            return (
              <TableRow key={`diff-${idx}`}>
                <TableCell component="th" scope="row">
                  {field}
                </TableCell>
                <TableCell>{getValue(a[field])}</TableCell>
                <TableCell>{getValue(b[field])}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

ObjectDiff.propTypes = {
  record: PropTypes.object,
  source_a: PropTypes.string,
  source_b: PropTypes.string,
  hide_fields: PropTypes.arrayOf(PropTypes.string),
}
