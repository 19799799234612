import React from "react"

import { makeStyles } from "@mui/styles"
import moment from "moment"

import {
  Create,
  DateInput,
  SelectInput,
  SimpleForm,
  ReferenceInput,
  TextInput,
  required,
} from "react-admin"

import { TaskStatusInput } from "fields"

const useStyles = makeStyles({
  inlineLeft: {
    display: "inline-flex",
    marginRight: "20px",
    width: "calc(50% - 10px)",
  },
  inlineRight: {
    display: "inline-flex",
    width: "calc(50% - 10px)",
  },
  fullwidth: {
    width: "100%",
  },
})

export const TaskCreate = (props) => {
  const classes = useStyles()
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput
          fullWidth
          label="Project"
          source="project_id"
          reference="projects"
          sort={{ field: "name", order: "ASC" }}
          filterToQuery={(searchText) => ({ name: searchText })}
          allowEmpty={true}
          validate={required()}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput fullWidth source="unique_id" label="Unique ID" />
        <TextInput
          fullWidth
          source="name"
          label="Task Name"
          validate={required()}
        />
        <TextInput fullWidth multiline source="description" />
        <TextInput fullWidth multiline source="outline_code" />
        <TextInput fullWidth multiline source="type" label="Type/Category" />
        <TextInput fullWidth source="responsible_party" />
        <TextInput fullWidth source="location" />
        <TaskStatusInput fullWidth source="status" validate={required()} />
        <DateInput
          source="sched_start_date"
          classes={{ root: classes.fullwidth }}
          formClassName={classes.inlineLeft}
          format={(v) => (v ? moment(v).format("YYYY-MM-DD") : null)}
          parse={(v) =>
            v ? moment.utc(v).set("hour", 12).toISOString() : null
          }
        />
        <DateInput
          source="sched_end_date"
          classes={{ root: classes.fullwidth }}
          formClassName={classes.inlineRight}
          format={(v) => (v ? moment(v).format("YYYY-MM-DD") : null)}
          parse={(v) =>
            v ? moment.utc(v).set("hour", 12).toISOString() : null
          }
        />
      </SimpleForm>
    </Create>
  )
}
