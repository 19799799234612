import React from "react"

import {
  Create,
  Datagrid,
  EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin"

export const UnionList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="abbreviation" />
        <TextField source="value" label="Full Name" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export const UnionCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="abbreviation" />
        <TextInput source="value" label="Full Name" fullWidth />
      </SimpleForm>
    </Create>
  )
}
