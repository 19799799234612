import React from "react"
import PropTypes from "prop-types"

import { SelectInput } from "react-admin"

export const JobTypeInput = ({ source, ...props }) => (
  <SelectInput
    {...props}
    source={source}
    choices={[
      { id: "full_job", name: "Full Job" },
      { id: "joblet", name: "Joblet" },
    ]}
  />
)

JobTypeInput.propTypes = {
  source: PropTypes.string,
}
