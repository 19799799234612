import React, { useState } from "react"
import {
  FormDataConsumer,
  Link,
  SimpleForm,
  SelectInput,
  TextInput,
  useDataProvider,
} from "react-admin"

import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material"

function getResourceName(id_type) {
  return {
    company_id: "companies",
    company_worker_id: "companyworkers",
    demandlead_id: "demandleads",
    job_application_id: "jobapplications",
    job_id: "jobs",
    project_id: "projects",
    task_id: "tasks",
    worker_id: "workers",
  }[id_type]
}

function cleanid(id) {
  return id.trim().replaceAll(",", "")
}

export const IDConverter = () => {
  const dataProvider = useDataProvider()
  const [convertedIDs, setConvertedIDs] = useState([])
  const [resource, setResource] = useState(null)

  const onSave = (record) => {
    setResource(getResourceName(record.id_type))
    const ids = record.ids.split("\n")
    const payload = {
      id_type: record.id_type,
      encoded_ids: [],
      decoded_ids: [],
    }
    for (const idstr of ids) {
      const id = cleanid(idstr)
      if (id.length == 0) {
        continue
      }

      if (!isNaN(id) && !isNaN(parseInt(id))) {
        payload.decoded_ids.push(parseInt(id))
      } else {
        payload.encoded_ids.push(id)
      }
    }
    dataProvider.post("/ids/convert", payload).then((response) => {
      const cleanids = ids.filter((id) => id.length)
      setConvertedIDs(
        cleanids.map((id) => {
          const idtrim = cleanid(id)
          if (!isNaN(idtrim) && !isNaN(parseInt(idtrim))) {
            for (const result of response.data) {
              if (result.id == idtrim) {
                return [parseInt(idtrim), result.encoded_id]
              }
            }
          } else {
            for (const result of response.data) {
              if (result.encoded_id == idtrim) {
                return [idtrim, result.id]
              }
            }
          }
        })
      )
    })
  }

  return (
    <Card>
      <SimpleForm toolbar={null}>
        <SelectInput
          source="id_type"
          label="ID Type"
          choices={[
            { id: "async_upload_id", name: "Async Upload ID" },
            { id: "campaign_id", name: "Campaign ID" },
            { id: "company_id", name: "Company ID" },
            { id: "company_worker_id", name: "Company Worker ID" },
            { id: "subscription_id", name: "Subscription ID" },
            { id: "demandlead_id", name: "Demand Lead ID" },
            { id: "group_id", name: "Group ID" },
            { id: "group_member_id", name: "Group Member ID" },
            { id: "job_application_id", name: "Job Application ID" },
            { id: "job_id", name: "Job ID" },
            { id: "login_id", name: "Login ID" },
            { id: "message_id", name: "Message ID" },
            { id: "message_thread_id", name: "Message Thread ID" },
            { id: "project_id", name: "Project ID" },
            { id: "report_result_id", name: "Report Result ID" },
            { id: "task_id", name: "Task ID" },
            { id: "verification_id", name: "Verification ID" },
            { id: "worker_id", name: "Worker ID" },
          ]}
        />
        <TextInput source="ids" fullWidth multiline rows={10} />

        <FormDataConsumer>
          {({ formData }) => (
            <Button
              disabled={!formData.id_type || !formData.ids}
              onClick={() => onSave(formData)}
              variant="contained"
              type="button"
            >
              Convert
            </Button>
          )}
        </FormDataConsumer>
      </SimpleForm>

      {convertedIDs.length ? (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Before</TableCell>
                <TableCell>After</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {convertedIDs.map((conversion, idx) => {
                return (
                  <TableRow key={`diff-${idx}`}>
                    <TableCell>{conversion[0]}</TableCell>
                    {isNaN(conversion[1]) && resource ? (
                      <TableCell>
                        <Link to={`/${resource}/${conversion[1]}/edit`}>
                          {conversion[1]}
                        </Link>
                      </TableCell>
                    ) : (
                      <TableCell>{conversion[1]}</TableCell>
                    )}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </Card>
  )
}
