import React from "react"

import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  List,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin"
import { makeStyles } from "@mui/styles"

import { SkillCategoryInput } from "./fields/skill_category_input"

const useStyles = makeStyles({
  skill: {
    marginBottom: "8px",
    marginLeft: ".5rem",
    minWidth: "250px",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
  autocomplete: {
    marginBottom: "1.5rem",
    paddingTop: "9px",
    minWidth: "300px",
  },
  inputRoot: {
    paddingTop: "11px !important",
  },
})

const SkillFilter = (props) => {
  const classes = useStyles()
  return (
    <Filter {...props}>
      <SelectInput
        choices={[
          { id: "general", name: "General" },
          { id: "trade", name: "Trade" },
        ]}
        source="type"
        alwaysOn
      />
      <SkillCategoryInput
        classes={{ inputRoot: classes.inputRoot }}
        formClassName={classes.skill}
        source="category"
        alwaysOn
      />
    </Filter>
  )
}
export const SkillList = (props) => (
  <List {...props} hasCreate={true} filters={<SkillFilter />}>
    <Datagrid>
      <TextField source="tag" />
      <TextField source="type" />
      <TextField source="category" />
      <TextField source="action" />
      <TextField source="suggestion_weight" />
      <EditButton />
    </Datagrid>
  </List>
)

export const SkillCreate = (props) => {
  const classes = useStyles()
  return (
    <Create {...props} hasCreate={true}>
      <SimpleForm
        defaultValues={{ status: "active", action: "", suggestion_weight: 1 }}
      >
        <SelectInput
          choices={[
            { id: "general", name: "General" },
            { id: "trade", name: "Trade" },
          ]}
          source="type"
        />
        <SelectInput
          choices={[{ id: "auto_assign", name: "Auto Assign" }]}
          source="action"
        />
        <NumberInput source="suggestion_weight" />
        <SkillCategoryInput
          source="category"
          formClassName={classes.autocomplete}
        />
        <TextInput source="tag" />
        <TextInput source="description" fullWidth />
      </SimpleForm>
    </Create>
  )
}

export const SkillEdit = (props) => {
  const classes = useStyles()
  return (
    <Edit {...props}>
      <SimpleForm>
        <SelectInput
          choices={[
            { id: "general", name: "General" },
            { id: "trade", name: "Trade" },
          ]}
          source="type"
        />
        <SelectInput
          choices={[{ id: "auto_assign", name: "Auto Assign" }]}
          source="action"
        />
        <NumberInput source="suggestion_weight" />
        <SkillCategoryInput
          source="category"
          formClassName={classes.autocomplete}
        />
        <TextInput source="tag" />
        <TextInput source="description" fullWidth />
      </SimpleForm>
    </Edit>
  )
}
