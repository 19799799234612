import React from "react"
import PropTypes from "prop-types"

import {
  Create,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  required,
} from "react-admin"

import { CustomListActions } from "../list_actions"

import { WorkerCardURLCopyButton, WorkerSourceTypeInput } from "../fields"
import { phoneValidate } from "./worker_shared"

const WorkerFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Keyword" source="q" alwaysOn />
    <TextInput label="Mobile number" source="mobile_number" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
    <SelectInput
      label="Status"
      source="status"
      alwaysOn
      choices={[
        { id: "invited", name: "Invited" },
        { id: "open", name: "Open" },
        { id: "anonymous", name: "Anonymous" },
        { id: "closed", name: "Closed" },
      ]}
    />
    <TextInput label="Address" source="address_txt" />
    <TextInput label="Distance Origin" source="distance.origin" />
    <NumberInput label="Distance Range" source="distance.radius" />
  </Filter>
)

WorkerFilter.propTypes = {
  filterDefaultValues: PropTypes.object,
}

const WorkerURLsField = ({ record }) => {
  if (!record) {
    return null
  }

  return (
    <div>
      {record.resume_url ? (
        <p>
          <a href={record.resume_url}>Resume</a>
        </p>
      ) : null}
      {record.linkedin_url ? (
        <p>
          <a href={record.linkedin_url}>LinkedIn</a>
        </p>
      ) : null}
      {record.indeed_url ? (
        <p>
          <a href={record.indeed_url}>Indeed</a>
        </p>
      ) : null}
    </div>
  )
}

WorkerURLsField.propTypes = {
  record: PropTypes.object,
}

export const WorkerList = ({ hasEdit, hasList, ...props }) => {
  return (
    <List
      {...props}
      hasEdit={hasEdit}
      hasList={hasList}
      actions={<CustomListActions {...props} maxResults={10000} />}
      filterDefaultValues={{ status: "open" }}
      filters={<WorkerFilter />}
    >
      <Datagrid>
        <EditButton resource="workers" label="Edit" />
        <TextField source="status" />
        <TextField source="first_name" />
        <TextField source="last_name" />
        <TextField source="mobile_number" />
        <TextField source="email" />
        <WorkerCardURLCopyButton label="Worker Card" source="id" />
        <WorkerURLsField label="URLs" />
        <DateField source="time_created" showTime />
      </Datagrid>
    </List>
  )
}

WorkerList.propTypes = {
  record: PropTypes.object,
  hasEdit: PropTypes.bool,
  hasList: PropTypes.bool,
}

export const WorkerCreate = (props) => (
  <Create {...props}>
    <SimpleForm
      defaultValues={{
        status: "open",
        agree_privacy_policy: false,
        agree_digest_sms: false,
      }}
      warnWhenUnsavedChanges
    >
      <WorkerSourceTypeInput source="source_type" />
      <SelectInput
        label="Status"
        source="status"
        alwaysOn
        choices={[
          { id: "invited", name: "Invited" },
          { id: "open", name: "Open" },
          { id: "anonymous", name: "Anonymous" },
          { id: "closed", name: "Closed" },
        ]}
      />
      <TextInput source="mobile_number" validate={phoneValidate} />
      <TextInput source="source" label="Worker Source" fullWidth />
      <TextInput source="first_name" validate={required()} />
      <TextInput source="last_name" validate={required()} />
    </SimpleForm>
  </Create>
)
