import React from "react"
import PropTypes from "prop-types"
import Config from "../config"

export const URLField = ({ record, source, label_source, label }) => {
  const text = record[label_source] || label || "Link"
  return record[source] ? <a href={record[source]}>{text}</a> : null
}

URLField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  label_source: PropTypes.string,
  label: PropTypes.string,
}

export const JobFlyerURLField = ({ className, record, source }) => {
  const name = "Job Flyer"
  if (!record) {
    return null
  }

  return (
    <p>
      <a
        target="_blank"
        rel="noopener noreferrer"
        className={className}
        href={`${Config.jbURL}/jobflyer/${record[source]}`}
      >
        {name}
      </a>
    </p>
  )
}

JobFlyerURLField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
  className: PropTypes.string,
}
