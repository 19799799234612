import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import Papa from "papaparse"
import {
  ArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  Link,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Title,
  Toolbar,
  useInput,
} from "react-admin"

import { Button, Card, CardContent, Checkbox } from "@mui/material"
import { makeStyles } from "@mui/styles"

import { parse } from "query-string"

import {
  AddressInput,
  AvailabilityInput,
  JobTypeInput,
  TradeInput,
  YearsOfXPInput,
} from "fields"

import { dataProvider } from "data_provider"

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    display: "inline-flex",
    "justify-items": "end",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  button: {
    display: "inline-block",
    margin: theme.spacing(1),
  },
  tooltip: {
    fontSize: "14px",
  },
}))

function parseCSV(csvText) {
  const results = Papa.parse(csvText, {
    skipEmptyLines: true,
    escapeChar: "\\",
  })
  return results.data.map((job) => {
    const [title, desc, trade, loc, xp] = job
    return {
      result: {},
      job_title: title,
      job_description: desc,
      trade: trade.toLowerCase(),
      pay_type: "salary",
      pay_low: 0,
      job_location: loc,
      years_of_xp: xp,
    }
  })
}

const MassCreateToolbar = (props) => {
  return (
    <Toolbar {...props} margin="dense">
      <SaveButton
        label="Create"
        undoable={false}
        disabled={props.pristine}
        type="button"
      />
    </Toolbar>
  )
}

MassCreateToolbar.propTypes = {
  pristine: PropTypes.bool,
  record: PropTypes.object,
}

async function create_job(job, record) {
  job.company_id = record.company_id
  job.status = "preview"
  job.type = record.type
  job.headcount = 1
  job.pay_currency = "USD"
  job.availability = record.availability
  try {
    const createdJob = await dataProvider.create("jobs", {
      data: job,
    })
    return {
      success: true,
      id: createdJob.data.id,
      job_title: job.job_title,
    }
  } catch (err) {
    return {
      success: false,
      error: err,
    }
  }
}

const validate = (record) => {
  const errors = {}
  if (!record.company_id) {
    errors.company_id = "Company selection required"
  }
  return errors
}

export const JobMassCreate = ({ location }) => {
  const { company_id: company_id_str } = parse(location.search)
  const [results, setResults] = useState([])

  const onSave = async (record) => {
    const res = []
    for (let i = 0; i < record.jobs.length; i++) {
      const job_result = await create_job(record.jobs[i], record)
      Object.assign(record.jobs[i].result, job_result)
      res.push(job_result)
    }
    setResults(res.slice())
    return record
  }

  return (
    <Card>
      <Title title="Batch Create Jobs" />
      <CardContent>
        <SimpleForm
          record={{
            jobs: [],
            company_id: company_id_str,
            csv: "",
          }}
          toolbar={<MassCreateToolbar />}
          save={onSave}
          validate={validate}
          defaultValues={{ type: "full_job", availability: "full_time" }}
        >
          <ReferenceInput
            source="company_id"
            reference="companies"
            allowEmpty
            fullWidth
            perPage={200}
          >
            <AutocompleteInput
              allowEmpty
              optionText="company_name"
              optionValue="id"
            />
          </ReferenceInput>

          <JobTypeInput fullWidth source="type" />

          <AvailabilityInput fullWidth source="availability" />

          <FormContents results={results} />
        </SimpleForm>
      </CardContent>
    </Card>
  )
}

JobMassCreate.propTypes = {
  location: PropTypes.object,
}

const FormContents = ({ results }) => {
  // eslint-disable-line no-unused-vars
  const [csvDisabled, setCSVDisabled] = useState(false)
  const [previewDisabled, setPreviewDisabled] = useState(false)
  const [cancelDisabled, setCancelDisabled] = useState(true)

  const jobs = useInput({ source: "jobs" })

  const classes = useStyles()
  return (
    <>
      <h4>Columns: Title,Description,Trade,Location,Years of XP</h4>
      <TextInput
        source="csv"
        disabled={csvDisabled}
        fullWidth
        multiline
        rows={10}
      />

      <FormDataConsumer>
        {({ formData }) => {
          const handleCancel = () => {
            setCSVDisabled(false)
            setCancelDisabled(true)
            setPreviewDisabled(false)
            jobs.input.onChange([])
          }

          const handlePreview = () => {
            let active = true
            setCSVDisabled(true)
            setCancelDisabled(false)
            setPreviewDisabled(true)
            setTimeout(() => {
              const rows = parseCSV(formData.csv)
              if (active) {
                jobs.input.onChange(rows)
              }
            }, 0)
            return () => (active = false)
          }

          return (
            <div className={classes.buttonRoot}>
              <Button
                variant="contained"
                color="primary"
                disabled={cancelDisabled}
                onClick={handleCancel}
              >
                Cancel&nbsp;Preview
              </Button>

              <Button
                variant="contained"
                color="primary"
                disabled={previewDisabled}
                onClick={handlePreview}
              >
                Preview
              </Button>
            </div>
          )
        }}
      </FormDataConsumer>

      <EditableJobTable results={results} />
    </>
  )
}

FormContents.propTypes = {
  results: PropTypes.array,
}

JobMassCreate.propTypes = {}

const CreateResult = (props) => {
  const {
    input: { value },
  } = useInput(props)

  if (value && value.success) {
    return (
      <>
        <Checkbox disabled checked={true} />
        <span>Success! </span>
        <Link target="_blank" to={`/jobs/${value.id}`}>
          {value.job_title}
        </Link>
      </>
    )
  } else if (value && value.error) {
    return (
      <>
        <Checkbox disabled checked={false} /> <span>Error! {value.error} </span>
      </>
    )
  } else {
    return <p>Server Results</p>
  }
}

CreateResult.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
}

// eslint-disable-next-line no-unused-vars
const EditableJobTable = ({ results }) => {
  const [trades, setTrades] = useState([])
  useEffect(() => {
    dataProvider.getTrades().then((tradesResp) => {
      setTrades(tradesResp.json)
    })
  }, [])

  return (
    <ArrayInput source="jobs">
      <SimpleFormIterator>
        <CreateResult source="result" />
        <TextInput fullWidth source="job_title" label="Title" />
        <TextInput
          fullWidth
          multiline
          source="job_description"
          label="Description"
        />
        <TradeInput
          source="trade"
          label="Trade"
          validate={validateTrade(trades)}
          fullWidth
        />
        <AddressInput source="job_location" label="Location" fullWidth />
        <YearsOfXPInput
          source="years_of_xp"
          label="Experience"
          validate={validateYearsOfXP}
        />
      </SimpleFormIterator>
    </ArrayInput>
  )
}

EditableJobTable.propTypes = {
  results: PropTypes.array,
}

function validateTrade(trades) {
  return (trade) => {
    if (trades.indexOf(trade) < 0) {
      return `"${trade}" is not a valid trade`
    }
  }
}

function validateYearsOfXP(experience) {
  const possibleValues = ["0-1", "2-4", "5-10", "11-15", "16-20", "20+"]
  if (possibleValues.indexOf(experience) < 0) {
    return `${experience} is not a possible value`
  }
}
