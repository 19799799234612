import React, { useEffect } from "react"
import PropTypes from "prop-types"

import { ReferenceArrayInput, required } from "react-admin"
import { useInput } from "react-admin"

import { get, uniq } from "lodash"
import { SkillChooserInput } from "./skill_chooser_input"
import { dataProvider } from "../data_provider"

export const AutoAssignTradeSkillsInput = ({ trade, ...props }) => {
  const tradeSkills = useInput({ source: "trade_skills" })

  useEffect(() => {
    if (trade) {
      dataProvider
        .getList("approvedskills", {
          filter: {
            type: "trade",
            category: trade,
            action: "auto_assign",
          },
          pagination: { page: 1, perPage: 100 },
          sort: ["id", "ASC"],
        })
        .then(({ data }) => {
          const skillIds = data.map((skill) => skill.id)
          const nextTradeSkillIds = uniq(
            get(props, "record.trade_skills", []).concat(skillIds)
          )
          tradeSkills.input.onChange("trade_skills", nextTradeSkillIds)
        })
    }
  }, [trade]) // eslint-disable-line

  return (
    <ReferenceArrayInput
      {...props}
      label="Trade Skills"
      source="trade_skills"
      reference="approvedskills"
      resource="approvedskills"
      allowEmpty
      fullWidth
      perPage={500}
      sort={{ field: "category", order: "ASC" }}
      validate={required()}
    >
      <SkillChooserInput />
    </ReferenceArrayInput>
  )
}

AutoAssignTradeSkillsInput.propTypes = {
  trade: PropTypes.string,
}
