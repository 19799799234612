import React from "react"

import {
  AutocompleteInput,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
} from "react-admin"

import { ProjectInput } from "fields"

const ProjectFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      alwaysOn
      fullWidth
      label="Company"
      source="company_id"
      reference="companies"
      sort={{ field: "company_name", order: "ASC" }}
      filterToQuery={(searchText) => ({ q: searchText })}
    >
      <AutocompleteInput optionText="company_name" source="company_name" />
    </ReferenceInput>
    <ProjectInput alwaysOn source="id" label="Project Name" />
  </Filter>
)

export const ProjectList = (props) => {
  return (
    <List
      {...props}
      filter={{ time_removed: null }}
      filters={<ProjectFilter />}
    >
      <Datagrid>
        <ReferenceField
          source="company_id"
          reference="companies"
          sortable={false}
        >
          <TextField source="company_name" />
        </ReferenceField>
        <TextField source="name" />
        <DateField source="time_created" />
        <DateField source="time_updated" />
        <EditButton />
      </Datagrid>
    </List>
  )
}
