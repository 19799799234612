import React from "react"
import PropTypes from "prop-types"

import { AutocompleteInput, ReferenceInput, required } from "react-admin"

export const ProjectInput = ({ source, company_id, ...rest }) => {
  return (
    <ReferenceInput
      {...rest}
      fullWidth
      label="Project"
      filterToQuery={(searchText) => {
        if (company_id) {
          return { q: searchText, company_id: company_id }
        } else {
          return { q: searchText }
        }
      }}
      source={source || "project_id"}
      reference="projects"
      sort={{ field: "name", order: "ASC" }}
      validate={required()}
    >
      <AutocompleteInput
        optionText="name"
        source="name"
        matchSuggestion={(filter, choice) => {
          if (choice && choice.name) {
            return choice.name.toLowerCase().includes(filter)
          }
          return false
        }}
      />
    </ReferenceInput>
  )
}

ProjectInput.propTypes = {
  company_id: PropTypes.string,
  source: PropTypes.string,
}
