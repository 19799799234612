import React from "react"
import PropTypes from "prop-types"

import { SelectArrayInput } from "react-admin"

export const WorkingDaysInput = ({ source, ...props }) => {
  return (
    <SelectArrayInput
      {...props}
      source={source}
      choices={[
        { id: "monday", name: "Monday" },
        { id: "tuesday", name: "Tuesday" },
        { id: "wednesday", name: "Wednesday" },
        { id: "thursday", name: "Thursday" },
        { id: "friday", name: "Friday" },
        { id: "saturday", name: "Saturday" },
        { id: "sunday", name: "Sunday" },
      ]}
    />
  )
}

WorkingDaysInput.propTypes = {
  source: PropTypes.string,
}
