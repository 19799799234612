import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import _ from "lodash"
import { useGetList, useInput } from "react-admin"

export const SkillCategoryInput = ({
  source,
  filter = {},
  classes,
  formClassName,
}) => {
  const [options, setOptions] = useState([])
  const {
    field: { value, onChange },
    fieldState: { isTouched, error },
  } = useInput({ source })

  const { data, loading } = useGetList("approvedskills", {
    pagination: { page: 1, perPage: 500 },
    filter: filter,
    sort: ["category", "ASC"],
  })

  useEffect(() => {
    if (data) {
      const categories = _.uniq(data.map((skill) => skill.category))
      setOptions(categories)
    }
  }, [data])

  function filterChoices(options, params) {
    if (params && params.inputValue) {
      const filterLower = params.inputValue.toLowerCase()
      let matches = options.filter(
        (option) => option.toLowerCase().indexOf(filterLower) >= 0
      )
      for (let match of matches) {
        if (match.toLowerCase() === filterLower) {
          return matches
        }
      }
      return [params.inputValue, ...matches]
    }
    return options
  }

  if (loading || !data) return null

  return (
    <Autocomplete
      choices={options}
      getOptionLabel={(option) => option}
      isOptionEqualToValue={(o, v) => o === v}
      value={value || null}
      variant="filled"
      className={formClassName}
      classes={classes}
      error={isTouched && error ? error : null}
      options={options}
      filterOptions={filterChoices}
      onChange={(event, newValue) => {
        if (newValue) {
          let present = options.filter((opt) => opt === newValue)
          if (present.length === 0) {
            setOptions([newValue, ...options])
          }
          onChange(newValue)
        } else {
          onChange(null)
        }
      }}
      renderInput={(params) => (
        <TextField {...params} variant="filled" label="Category" />
      )}
    />
  )
}

SkillCategoryInput.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
  formClassName: PropTypes.string,
  filter: PropTypes.object,
  classes: PropTypes.object,
}
