import React, { useState } from "react"

import {
  // eslint-disable-next-line
  form,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material"

import { Title } from "react-admin"
import { JobApplicationReport } from "./jobapp_report"
import { RemoteReports } from "./remote_reports"
import { RequestRemoteReports } from "./request_remote_reports"

export const ReportsView = () => {
  const [report, setReport] = useState("complete_jobapps")

  const handleChange = (e) => setReport(e.target.value)

  return (
    <Card>
      <Title title="Reports" />
      <CardContent>
        <form autoComplete="off">
          <FormControl>
            <InputLabel htmlFor="id-report">Report Type</InputLabel>
            <Select
              value={report}
              onChange={handleChange}
              inputProps={{
                name: "report",
                id: "id-report",
              }}
            >
              <MenuItem value={"complete_jobapps"}>Company Applicants</MenuItem>
              <MenuItem value={"remote_reports"}>Server Reports</MenuItem>
              <MenuItem value={"request_remote_reports"}>
                Request Reports
              </MenuItem>
            </Select>
          </FormControl>
        </form>
        {report === "complete_jobapps" ? <JobApplicationReport /> : null}
        {report === "remote_reports" ? <RemoteReports /> : null}
        {report === "request_remote_reports" ? <RequestRemoteReports /> : null}
      </CardContent>
    </Card>
  )
}
