import React from "react"

import { get } from "lodash"
import DeleteIcon from "@mui/icons-material/Delete"

import { useInput } from "react-admin"
import ReactDataGrid from "react-data-grid"
import { Editors } from "react-data-grid-addons"

const { DropDownEditor } = Editors

const issueTypes = [
  { id: "unsent", value: "unsent", text: "Unsent" },
  { id: "already", value: "already", text: "Already Sent" },
  { id: "disallowed", value: "disallowed", text: "Disallowed (phone)" },
  { id: "queued", value: "queued", text: "Queued" },
  { id: "sent", value: "sent", text: "Sent (do not use)" },
  { id: "removed", value: "removed", text: "Removed" },
]

const SendStatusEditor = <DropDownEditor options={issueTypes} />

const columns = [
  { key: "first_name", name: "First Name" },
  { key: "last_name", name: "Last Name" },
  { key: "mobile_number", name: "Phone" },
  { key: "worker_id", name: "Worker ID" },
  {
    key: "send_status",
    name: "Status",
    editable: true,
    editor: SendStatusEditor,
  },
]

const rowsCreator = (cw) => ({
  first_name: get(cw, "worker.first_name"),
  last_name: get(cw, "worker.last_name"),
  mobile_number: get(cw, "worker.mobile_number"),
  address_txt: get(cw, "worker.address_txt"),
  worker_id: get(cw, "worker.id"),
  send_status: get(cw, "send_status"),
})

export const CampaignWorkersTable = (props) => {
  const {
    field: { onChange, value },
  } = useInput(props)

  const count = get(value, "length", 0)

  const rows = (value || []).map(rowsCreator)

  function onGridRowsUpdated({ toRow, updated }) {
    const updValue = value.slice()
    updValue[toRow] = { ...updValue[toRow], send_status: updated.send_status }
    onChange(updValue)
  }

  const removeRow = (removeRow) => {
    const updVal = value.slice()
    for (let i = 0; i < updVal.length; i++) {
      if (updVal[i].worker_id === removeRow.worker_id) {
        updVal[i] = { ...updVal[i], status: "removed" }
      }
    }
    onChange(updVal)
  }

  const getCellActions = (column, row) => {
    if (column.key === "first_name") {
      return [
        {
          icon: <span style={{ width: "15px", height: "15px" }} />,
          callback: () => {},
        },
        {
          icon: <DeleteIcon style={{ marginTop: "6px" }} />,
          callback: () => removeRow(row),
        },
      ]
    }
    return null
  }

  return (
    <ReactDataGrid
      onGridRowsUpdated={onGridRowsUpdated}
      getCellActions={getCellActions}
      enableCellSelect={true}
      columns={columns}
      rowGetter={(i) => rows[i]}
      rowsCount={count}
      minHeight={150}
    />
  )
}
