import React from "react"
import moment from "moment"

import { TimeInput } from "fields"

import {
  ArrayInput,
  DateInput,
  BooleanInput,
  Edit,
  ReferenceField,
  SelectArrayInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
} from "react-admin"

import { TimezoneInput } from "fields"

export const ProjectEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <ReferenceField
          fullWidth
          label="Company"
          source="company_id"
          reference="companies"
        >
          <TextField source="company_name" />
        </ReferenceField>
        <TextInput source="name" fullWidth />
        <TextInput source="shortname" />
        <TextInput source="city" fullWidth />
        <TextInput source="state" fullWidth />
        <TextInput source="country" fullWidth />
        <TimezoneInput source="timezone" />
        <TimeInput source="default_task_start" />
        <TimeInput source="default_task_end" />
        <h3>Calendar</h3>
        <SelectArrayInput
          source="calendar.work_days"
          fullWidth
          choices={[
            { id: "monday", name: "Monday" },
            { id: "tuesday", name: "Tuesday" },
            { id: "wednesday", name: "Wednesday" },
            { id: "thursday", name: "Thursday" },
            { id: "friday", name: "Friday" },
            { id: "saturday", name: "Saturday" },
            { id: "sunday", name: "Sunday" },
          ]}
        />
        <ArrayInput source="calendar.exceptions" label="Calendar Exceptions">
          <SimpleFormIterator>
            <DateInput
              source="date"
              label="Exception Date"
              format={(v) => (v ? moment(v).format("YYYY-MM-DD") : null)}
              parse={(v) => (v ? moment.utc(v).format("YYYY-MM-DD") : null)}
            />
            <BooleanInput
              source="working"
              label="Off - Holiday / On - Work Day"
              fullWidth
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  )
}
