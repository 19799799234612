import React, { Fragment } from "react"
import PropTypes from "prop-types"

import {
  FormDataConsumer,
  SelectInput,
  NumberInput,
  TextInput,
  required,
  maxLength,
  minLength,
  useInput,
} from "react-admin"

import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
  inline: {
    display: "inline-flex",
    marginRight: "1rem",
  },
})

const PayLowHourly = (props) => {
  const classes = useStyles()
  return (
    <NumberInput
      {...props}
      source="pay_low"
      className={classes.inline}
      validate={required()}
    />
  )
}

const PayHighHourly = (props) => {
  const classes = useStyles()
  return (
    <NumberInput
      {...props}
      source="pay_high"
      className={classes.inline}
      validate={required()}
    />
  )
}

const PayFields = ({ formData }) => {
  switch (formData.pay_type) {
    case "hourly_rate":
      return <PayLowHourly label="Rate" />
    case "hourly_range":
      return (
        <Fragment>
          <PayLowHourly label="Min rate" />
          <PayHighHourly label="Max rate" />
        </Fragment>
      )
    case "salary":
      return <PayLowHourly label="Salary" />
    case "salary_range":
      return (
        <Fragment>
          <PayLowHourly label="Min salary" />
          <PayHighHourly label="Max salary" />
        </Fragment>
      )
    default:
      return null
  }
}

PayFields.propTypes = {
  formData: PropTypes.object,
}

export const PayInput = () => {
  const classes = useStyles()
  const payHighField = useInput({ source: "pay_high" })

  return (
    <Fragment>
      <SelectInput
        source="pay_type"
        className={classes.inline}
        choices={[
          { id: "hourly_rate", name: "Pay rate/hour" },
          { id: "hourly_range", name: "Pay range/hour" },
          { id: "salary", name: "Salary" },
          { id: "salary_range", name: "Salary range" },
        ]}
        onChange={(event) => {
          if (
            event.target.value === "hourly_rate" ||
            event.target.value === "salary"
          ) {
            payHighField.input.onChange("pay_high", undefined)
          }
        }}
        validate={required()}
      />
      <TextInput
        source="pay_currency"
        validate={[required(), maxLength(3), minLength(3)]}
        className={classes.inline}
      />
      <FormDataConsumer fullWidth>{PayFields}</FormDataConsumer>
    </Fragment>
  )
}
