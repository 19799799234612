import React from "react"

import {
  AutocompleteInput,
  Datagrid,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  ResourceContextProvider,
  SelectInput,
  TextField,
  downloadCSV,
  required,
} from "react-admin"

import Papa from "papaparse"
import { get, values } from "lodash"
import { ApplicationStatusInput, WorkerCardURLField } from "fields"
import { CustomListActions } from "list_actions"

import Config from "../config"

import { dataProvider } from "data_provider"

export const JobApplicationReport = (props) => {
  const resourceData = {
    resource: "jobapplications",
    hasList: true,
    hasEdit: false,
    hasShow: false,
    hasCreate: false,
  }
  return (
    <ResourceContextProvider value="jobapplications">
      <ReportList {...resourceData} {...props} />
    </ResourceContextProvider>
  )
}

async function exporter(records, fetchAssociated) {
  const jobMap = await fetchAssociated(records, "job_id", "jobs")
  const companyMap = await fetchAssociated(
    values(jobMap),
    "company_id",
    "companies"
  )
  const company = values(companyMap)[0]

  // Number of workers quite likely to exceed limit GET queries can handle, break it into
  // 100-job application chunks
  let workerMap = {}
  const chunkSize = 50
  for (let i = 0; i < records.length; i += chunkSize) {
    const chunk = records.slice(i, i + chunkSize)
    const chunkMap = await fetchAssociated(chunk, "worker_id", "workers")
    Object.assign(workerMap, chunkMap)
  }

  const rows = [
    [
      "Company",
      "Location",
      "Job Title",
      "Worker Name",
      "Worker Phone",
      "Worker Flyer URL",
      "Jobmatch Summary",
      "Worker Application Date",
    ],
  ]
  for (const record of records) {
    try {
      const job = jobMap[record.job_id]
      const worker = workerMap[record.worker_id]
      if (!job || !worker) {
        console.log(
          `Job or worker missing for worker ${record.worker_id} or job ${record.job_id}`
        )
        continue
      }
      const nbr = worker.mobile_number
      let phone = ""
      if (nbr && nbr.length == 12) {
        phone = `${nbr.slice(2, 5)}-${nbr.slice(5, 8)}-${nbr.slice(8)}`
      }

      const params = {
        filter_params: JSON.stringify({
          worker_id: record.worker_id,
          job_id: record.job_id,
        }),
      }
      const match = await dataProvider.get("/jobmatches", params)
      const summary = get(match, "data[0].summary", "")

      rows.push([
        get(company, "company_name", "Unnamed company"),
        get(job, "job_location", "Unspecified location"),
        get(job, "job_title", "Untitled job"),
        get(worker, "full_name") ||
          `${get(worker, "first_name")} ${get(worker, "last_name")}`,
        phone,
        `${Config.jbURL}/workercard/${record.worker_id}`,
        summary,
        record.application_status === "applied"
          ? get(record, "time_updated", "")
          : "",
      ])
    } catch (err) {
      console.log(
        `Error exporting job app for worker ${record.worker_id} job ${record.job_id}`
      )
    }
  }

  const csv = Papa.unparse(rows)
  downloadCSV(csv, `${company.company_name} Applicant Report`)
}

const ApplicantFilter = (props) => (
  <Filter {...props} style={{ width: "600px" }}>
    <ReferenceInput
      source="company_id"
      reference="companies"
      validate={required()}
      alwaysOn
      allowEmpty
      fullWidth
      perPage={500}
      style={{ width: "400px" }}
    >
      <AutocompleteInput
        style={{ width: "500px" }}
        allowEmpty
        optionText="company_name"
        optionValue="id"
      />
    </ReferenceInput>
    <SelectInput
      source="profile_level"
      alwaysOn
      choices={[
        { id: "match", name: "match" },
        { id: "complete", name: "complete" },
      ]}
    />
    <ApplicationStatusInput source="application_status" alwaysOn />
  </Filter>
)

const ReportList = (props) => {
  return (
    <List
      resource="jobapplications"
      location="/jobapplications"
      {...props}
      hasCreate={false}
      hasEdit={false}
      hasList
      hasShow
      filters={<ApplicantFilter />}
      actions={
        <CustomListActions
          showFilter={false}
          currentSort={{}}
          displayedFilters={{}}
          hasExport
          exporter={exporter}
          maxResults={5000}
        />
      }
    >
      <Datagrid>
        <ReferenceField label="Job Title" source="job_id" reference="jobs">
          <TextField source="job_title" label="Job Title" />
        </ReferenceField>
        <ReferenceField label="Location" source="job_id" reference="jobs">
          <TextField source="job_location" />
        </ReferenceField>
        <TextField source="application_status" />
        <ReferenceField
          label="Worker URL"
          source="worker_id"
          reference="workers"
        >
          <WorkerCardURLField source="id" />
        </ReferenceField>
        <ReferenceField label="Level" source="worker_id" reference="workers">
          <TextField source="profile_level" />
        </ReferenceField>
        <ReferenceField
          label="Worker Name"
          source="worker_id"
          reference="workers"
        >
          <TextField source="full_name" />
        </ReferenceField>
      </Datagrid>
    </List>
  )
}
