import { stringify } from "query-string"
import { authProvider } from "./firebase"
import { fetchUtils } from "ra-core"

/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import React from 'react';
 * import { Admin, Resource } from 'react-admin';
 * import simpleRestProvider from 'ra-data-simple-rest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */

const fetchJson = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: "application/json",
    })
  }
  const user = await authProvider.checkAuth()
  if (!user) {
    return Promise.reject()
  }

  const token = await user.getIdToken()

  options.headers.set("Authorization", "Bearer " + token)
  options.headers.set("x-journey-platform", "admin")
  return fetchUtils.fetchJson(url, options).catch((err) => {
    // This seems to be helpful to prevent errors on unmounting after
    // logout, as getIdToken will throw an error that fetchJson isn't
    // expecting
    console.log("fetchJson error", err, "on url", url)
    throw err
  })
}

const createDataProvider = (apiUrl, httpClient = fetchJson) => {
  const resourceURL = (resource, id) => {
    if (id !== undefined) {
      return `${apiUrl}/${resource}/${id}`
    }
    return `${apiUrl}/${resource}`
  }

  const nestedResourceURL = (id, resource, query) => {
    switch (resource) {
      case "jobs":
        return `${apiUrl}/companies/${id}/${resource}?${stringify(query)}`
      default:
        return `${apiUrl}/${resource}/${stringify(query)}`
    }
  }

  const uploadProjectImage = (companyID, imageFile) => {
    let formData = new FormData()
    formData.append("project_image", imageFile.rawFile, imageFile.filename)
    const url = `${apiUrl}/companies/${companyID}/project_image`
    return httpClient(url, {
      method: "POST",
      body: formData,
    })
  }

  const provider = {}

  provider.post = (path, body) => {
    return httpClient(`${apiUrl}${path}`, {
      method: "POST",
      body: body ? JSON.stringify(body) : undefined,
    }).then(({ json }) => ({
      data: json,
    }))
  }

  provider.get = (path, params) => {
    if (params) {
      return httpClient(`${apiUrl}${path}?${stringify(params)}`).then(
        ({ json }) => ({ data: json })
      )
    }
    return httpClient(`${apiUrl}/${path}`).then(({ json }) => ({ data: json }))
  }

  provider.grantRole = async (fbID, userName, role) => {
    let url = `${apiUrl}/grant_role`
    return httpClient(url, {
      method: "POST",
      body: JSON.stringify({ user_id: fbID, user_name: userName, role: role }),
    })
  }

  provider.clearUnread = async (workerid) => {
    let url = `${apiUrl}/workers/${workerid}/chats/mark_all_as_read`
    return httpClient(url, {
      method: "POST",
    })
  }

  provider.getVerificationChat = (verificationID) => {
    return httpClient(`${apiUrl}/verificationrequests/${verificationID}/chat`)
  }

  provider.uploadProjectImages = async (companyID, images) => {
    images = images.slice()
    for (let i = 0; i < images.length; i++) {
      if (images[i].rawFile instanceof File) {
        let resp = await uploadProjectImage(companyID, images[i])
        images[i] = resp.json.url
      } else if (images[i].src) {
        images[i] = images[i].src
      }
    }
    return images
  }

  provider.uploadLogoImage = async (companyID, logo) => {
    if (logo.rawFile instanceof File) {
      let formData = new FormData()
      formData.append("logo_pic", logo.rawFile, logo.filename)
      const url = `${apiUrl}/companies/${companyID}/logo`
      let resp = await httpClient(url, {
        method: "POST",
        body: formData,
      })
      return resp.json.url
    }
    return logo
  }

  provider.uploadResume = async (workerID, resume) => {
    if (resume.rawFile instanceof File) {
      let resumeData = new FormData()
      resumeData.append("resume", resume.rawFile, resume.filename)
      const url = `${apiUrl}/workers/${workerID}/resume`
      let resp = await httpClient(url, {
        method: "POST",
        body: resumeData,
      })
      return resp.json.resume_url
    }
    return resume
  }

  provider.getTrades = () => {
    return httpClient(`${apiUrl}/trades`)
  }

  provider.addWorkersToCampaign = (campaignId, workers) => {
    const url = `${apiUrl}/campaigns/${campaignId}/campaignworkers`
    return httpClient(url, {
      method: "POST",
      body: JSON.stringify(workers),
    })
  }

  provider.createCompanyForWorker = (workerID, companyName) => {
    const url = `${apiUrl}/workers/${workerID}/companies`
    return httpClient(url, {
      method: "POST",
      body: JSON.stringify({
        company_name: companyName,
      }),
    })
  }

  provider.uploadWorkerImage = (workerID, formData) => {
    const url = `${apiUrl}/workers/${workerID}/profilepic`
    return httpClient(url, {
      method: "POST",
      body: formData,
    })
  }

  provider.getCompaniesByWorkerID = (workerID) => {
    const url = `${apiUrl}/workers/${workerID}/companies`
    return httpClient(url, {
      method: "GET",
    })
  }

  provider.getList = (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort

    const query = {
      sort_field: field,
      sort_order: order,
      offset: (page - 1) * perPage,
      limit: perPage,
      filter_params: JSON.stringify(params.filter),
    }

    const url = `${apiUrl}/${resource}?${stringify(query)}`

    return httpClient(url).then(({ headers, json }) => {
      if (!headers.has("content-range")) {
        throw new Error(
          "The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?"
        )
      }
      return {
        data: json,
        total: parseInt(headers.get("content-range").split("/").pop(), 10),
      }
    })
  }

  provider.getOne = (resource, params) => {
    const url = resourceURL(resource, params.id)
    return httpClient(url).then(({ json }) => ({
      data: json,
    }))
  }

  provider.getMany = (resource, params) => {
    const query = {
      filter_params: JSON.stringify({ id: params.ids }),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    return httpClient(url).then(({ json }) => ({ data: json }))
  }

  provider.getManyReference = (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      sort_field: field,
      sort_order: order,
      offset: (page - 1) * perPage,
      limit: page * perPage - 1,
      filter_params: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    }
    const url = nestedResourceURL(params.id, resource, query)

    return httpClient(url).then((response) => {
      const { headers, json } = response
      if (!headers.has("content-range")) {
        throw new Error(
          "The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?"
        )
      }
      return {
        data: json,
        total: parseInt(headers.get("content-range").split("/").pop(), 10),
      }
    })
  }

  provider.update = async (resource, params) => {
    const url = resourceURL(resource, params.id)
    return httpClient(url, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then((response) => {
      const { json } = response
      return { data: json }
    })
  }

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  provider.updateMany = (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "PUT",
          body: JSON.stringify(params.data),
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) }))

  provider.create = (resource, params) => {
    let url
    if (resource === "jobs") {
      url = `${apiUrl}/companies/${params.data.company_id}/jobs`
    } else if (resource === "projects") {
      url = `${apiUrl}/companies/${params.data.company_id}/projects`
    } else if (resource === "tasks") {
      url = `${apiUrl}/projects/${params.data.project_id}/tasks`
    } else {
      url = resourceURL(resource)
    }

    return httpClient(url, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }))
  }

  provider.delete = (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json }))
  }

  provider.deleteManyJobs = (ids) => {
    return provider
      .getMany("jobs", { ids: ids })
      .then((resp) => {
        return Promise.all(
          resp.data.map((job) => {
            job.status = "canceled"
            return provider.update("jobs", {
              id: job.id,
              data: job,
            })
          })
        )
      })
      .then((responses) => {
        return {
          data: responses.map(({ data }) => data.id),
        }
      })
  }

  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  provider.deleteMany = (resource, params) => {
    if (resource === "jobs") {
      return provider.deleteManyJobs(params.ids)
    }

    return Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "DELETE",
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) }))
  }

  return provider
}

const API_URL = process.env.REACT_APP_API_URL
export const dataProvider = createDataProvider(API_URL)
