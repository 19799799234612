import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import { useInput } from "react-admin"
import Autocomplete from "@mui/material/Autocomplete"
import { TextField } from "@mui/material"

import { useDataProvider } from "react-admin"

// eslint-disable-next-line no-unused-vars
export const UnionArrayInput = ({ basePath, ...props }) => {
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = useState([])
  const [value, setValue] = React.useState([])
  const loading = open && options.length === 0
  const dataprovider = useDataProvider()

  useEffect(() => {
    if (props.record && props.record.union_info) {
      setValue(
        props.record.union_info.map((v) => {
          return {
            id: v,
            name: v,
          }
        })
      )
    }
  }, [props.record])

  useEffect(() => {
    let active = true

    dataprovider.get("unions?limit=500").then((response) => {
      if (active) {
        setOptions(
          response.data.map((v) => {
            return {
              id: v.id,
              name: v.value,
            }
          })
        )
      }
    })

    return () => {
      active = false
    }
  }, [])

  useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  const {
    field: { onChange },
    fieldState: { isTouched, error },
  } = useInput(props)

  function filterChoices(options, params) {
    if (params && params.inputValue) {
      const filterLower = params.inputValue.toLowerCase()
      let matches = options.filter(
        (choice) => choice.name.toLowerCase().indexOf(filterLower) >= 0
      )
      for (let match of matches) {
        if (match.name.toLowerCase() === filterLower) {
          return matches
        }
      }
      return [{ id: params.inputValue, name: params.inputValue }, ...matches]
    }
    return options
  }

  return (
    <Autocomplete
      {...props}
      error={isTouched && error ? error : undefined}
      filterOptions={filterChoices}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => {
        return option.name == value.name
      }}
      loading={loading}
      multiple
      name="unions_input"
      open={open}
      options={options}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(event, newValue) => {
        for (const val of newValue) {
          // If this is a new value introduced by filterChoices, it must
          // be introduced into the options array
          let present = options.filter((opt) => opt.id === val.id)
          if (present.length === 0) {
            setOptions([val, ...options])
          }
        }
        setValue(newValue)
        // Form/db wants array of strings.  Maybe eventually use parse/format
        // to fix this one
        onChange(newValue.map((v) => v.name))
      }}
      value={value}
      variant="standard"
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label="Unions"
          placeholder="Favorites"
        />
      )}
      style={{ marginBottom: "1.5rem" }}
    />
  )
}

UnionArrayInput.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
  basePath: PropTypes.string,
}
