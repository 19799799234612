import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import {
  AutocompleteInput,
  AutocompleteArrayInput,
  Loading,
  useEditContext,
  useInput,
} from "react-admin"
import { dataProvider } from "data_provider"

export const TradeInput = ({ source, multiple, ...props }) => {
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [trades, setTrades] = useState(null)
  const { record } = useEditContext()

  useEffect(() => {
    setLoading(true)
    dataProvider
      .getTrades()
      .then((response) => {
        setTrades(response.json)
      })
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (trades) {
      setOptions(trades.map((opt) => ({ id: opt, name: opt })))
    } else if (record && record[source]) {
      const value = record && record[source]
      if (value) {
        if (multiple) {
          setOptions(value.map((v) => ({ id: v, name: v })))
        } else {
          setOptions([{ id: value, name: value }])
        }
      }
    }
  }, [multiple, record, trades])

  if (loading || trades == null) return <Loading />

  if (multiple) {
    return (
      <AutocompleteArrayInput
        {...props}
        source={source}
        choices={options}
        label="Trade"
      />
    )
  } else {
    return (
      <AutocompleteInput
        {...props}
        source={source}
        choices={options}
        label="Trade"
      />
    )
  }
}

TradeInput.propTypes = {
  source: PropTypes.string,
  multiple: PropTypes.bool,
  record: PropTypes.object,
}

export const DesiredTradesInput = (props) => {
  const {
    field: { value, onChange },
  } = useInput(props)
  const tradeInput = useInput({ ...props, source: "trade" })

  useEffect(() => {
    if (value) {
      // If trade changed and selected trade isn't one of desired trades, automatically add it
      if (
        tradeInput.fieldState.isTouched &&
        value.indexOf(tradeInput.field.value) < 0
      ) {
        onChange([...value, tradeInput.field.value])
      }
    }
  }, [tradeInput.fieldState.isTouched, tradeInput.field.value])

  return <TradeInput multiple {...props} />
}

DesiredTradesInput.propTypes = {
  source: PropTypes.string,
}
