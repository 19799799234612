import React from "react"
import PropTypes from "prop-types"

import { SelectArrayInput } from "react-admin"

export const SkillChooserInput = (props) => {
  const labeler = (option) => {
    if (!option || !option.category || !option.tag) {
      return ""
    }
    return `${option.category} - ${option.tag}`
  }
  return (
    <SelectArrayInput
      fullWidth
      {...props}
      choices={props.choices}
      optionText={labeler}
      optionValue="id"
    />
  )
}

SkillChooserInput.propTypes = {
  choices: PropTypes.array,
}
