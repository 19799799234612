// import firebase from 'firebase/app'
// import 'firebase/auth'
// import 'firebase/firestore'
// import {FirebaseAuthProvider} from 'react-admin-firebase'

import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
  // FirebaseRealTimeSaga
} from "react-admin-firebase"

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,

  logging: true,
  persistence: "local",
}

// export const firebaseApp = firebase.initializeApp(firebaseConfig)
//
// export const auth = firebase.auth()
//
// auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
//
// export const firestore = firebase.firestore()
// export const authProvider = FirebaseAuthProvider(firebaseConfig, {logging: true})

export const dataProvider = FirebaseDataProvider(firebaseConfig, {})
export const authProvider = FirebaseAuthProvider(firebaseConfig, {})
// export const firebaseRealtime = FirebaseRealTimeSaga(dataProvider, {})
