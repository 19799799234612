import React from "react"
import PropTypes from "prop-types"

import {
  Create,
  Datagrid,
  List,
  ReferenceField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin"

const MultiRefLabel = (props) => {
  if (props && props.record && props.record.test_id_type) {
    if (props.record.test_id_type === "worker_id") {
      return (
        <ReferenceField source="test_id" reference="workers" sortable={false}>
          <TextField source="full_name" />
        </ReferenceField>
      )
    } else if (props.record.test_id_type === "company_id") {
      return (
        <ReferenceField source="test_id" reference="companies" sortable={false}>
          <TextField source="company_name" />
        </ReferenceField>
      )
    }
  }

  return null
}

MultiRefLabel.propTypes = {
  record: PropTypes.object,
}

export const TestIDList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="test_id" label="Test ID" />
        <MultiRefLabel label="Description" />
        <TextField source="test_id_type" label="ID Type" />
      </Datagrid>
    </List>
  )
}

export const TestIDCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="test_id" />
        <SelectInput
          source="test_id_type"
          choices={[
            { id: "company_id", name: "Company" },
            { id: "worker_id", name: "Worker" },
          ]}
        />
      </SimpleForm>
    </Create>
  )
}
