import React from "react"
import PropTypes from "prop-types"
import Config from "./config"

import {
  required,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  List,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  SelectInput,
  useRecordContext,
} from "react-admin"
import { ApplicationStatusInput } from "fields"
import { CustomListActions } from "./list_actions"
import { get } from "lodash"

const JobApplicationFilter = (props) => {
  return (
    <Filter {...props}>
      <ApplicationStatusInput alwaysOn source="application_status" />
      <TextInput label="Job ID" source="job_id" alwaysOn />
      <TextInput label="Worker ID" source="worker_id" alwaysOn />
      <SelectInput
        label="Type"
        source="job_type"
        alwaysOn
        choices={[
          { id: "full_job", name: "Full" },
          { id: "joblet", name: "Joblet" },
        ]}
      />
    </Filter>
  )
}

const WorkerCardField = ({ source }) => {
  const record = useRecordContext()
  const name = record[source]
  return (
    <a
      href={`${Config.jbURL}/workercard/${record[source]}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {name}
    </a>
  )
}

const WorkerNameField = () => {
  const record = useRecordContext()
  const contact_name = record.invite_contact_name
  const full_name = get(record, "worker.full_name", "")

  if (full_name == "") {
    if (contact_name) {
      return contact_name
    } else {
      return ""
    }
  } else {
    return full_name
  }
}

WorkerCardField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
}

const JobFlyerField = ({ source }) => {
  const record = useRecordContext()
  const name = record[source]
  return (
    <a
      href={`${Config.jbURL}/jobflyer/${record[source]}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {name}
    </a>
  )
}

JobFlyerField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
}

export const JobApplicationList = ({ hasEdit, hasList, ...props }) => (
  <List
    {...props}
    hasEdit={hasEdit}
    hasList={hasList}
    filters={<JobApplicationFilter />}
    actions={<CustomListActions {...props} hasCreate={true} />}
    filter={{}}
  >
    <Datagrid>
      <WorkerCardField label="card" source="worker_id" sortable={false} />
      <WorkerNameField label="name" source="record" sortable={false} />
      <TextField source="id" />
      <JobFlyerField source="job_id" />
      <DateField source="time_created" showTime />
      <DateField source="time_updated" showTime />
      <TextField source="application_status" />
      <TextField source="worker_read_status" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

JobApplicationList.propTypes = {
  hasEdit: PropTypes.bool,
  hasList: PropTypes.bool,
}

const JobApplicationForm = (props) => (
  <SimpleForm {...props}>
    <TextInput label="Worker ID" source="worker_id" validate={required()} />
    <TextInput label="Job ID" source="job_id" parse={(value) => value} />
    <ApplicationStatusInput source="application_status" />
    <SelectInput
      label="Read Status"
      source="worker_read_status"
      parse={(value) => value}
      choices={[
        { id: "unread", name: "Unread" },
        { id: "read", name: "Read" },
      ]}
    />
  </SimpleForm>
)

const preventNull = (record) => {
  if (!record.worker_id) {
    record = {
      ...record,
      worker_id: [],
    }
  }
  if (!record.job_id) {
    record = {
      ...record,
      job_id: [],
    }
  }
  if (!record.application_status) {
    record = {
      ...record,
      application_status: [],
    }
  }
  return record
}

export const JobApplicationCreate = (props) => (
  <Create {...props} transform={preventNull}>
    <JobApplicationForm {...props} />
  </Create>
)

export const JobApplicationEdit = (props) => (
  <Edit {...props} transform={preventNull}>
    <JobApplicationForm {...props} />
  </Edit>
)

export const JobApplicationShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Basic">
        <TextField source="id" />
        <TextField source="job_id" />
        <DateField source="time_created" showTime />
        <DateField source="time_updated" showTime />
        <TextField label="Status" source="application_status" />
        <TextField label="Read Status" source="worker_read_status" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)
