import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"

import * as d3 from "d3"
import moment from "moment"

import {
  ReferenceInput,
  DateInput,
  FormDataConsumer,
  AutocompleteInput,
  SimpleForm,
  SelectInput,
  useDataProvider,
  useNotify,
} from "react-admin"

import { makeStyles } from "@mui/styles"
import SearchIcon from "@mui/icons-material/Search"
import { Button, Card } from "@mui/material"
import { sortBy } from "lodash"

import useD3 from "hooks/useD3"

const useStyles = makeStyles({
  inline: {
    display: "inline-flex",
    marginRight: "1rem",
  },
})

export const RemoteReports = () => {
  const [reportResults, setReportResults] = useState([])
  const notify = useNotify()

  const dataProvider = useDataProvider()

  const classes = useStyles()
  const onSearch = (record) => {
    const params = { ...record }
    delete params.company_id
    dataProvider
      .get(`/companies/${record.company_id}/reportresults`, params)
      .then((response) => {
        if (response.data.length == 0) {
          notify("No reports for this time period")
        }
        setReportResults(response.data)
      })
      .catch((err) => {
        notify(`Error fetching reports ${err}`, "warning")
      })
  }

  function checkForm(record) {
    const errors = {}
    if (!record.company_id) {
      errors.company_id = "Company required"
    }
    if (!record.name) {
      errors.name = "Report required"
    }
    return errors
  }

  return (
    <Card>
      <SimpleForm onSave={onSearch} toolbar={null} validate={checkForm}>
        <ReferenceInput
          fullWidth
          label="Company"
          source="company_id"
          reference="companies"
          sort={{ field: "company_name", order: "ASC" }}
          filterToQuery={(searchText) => ({ q: searchText })}
          allowEmpty={true}
          alwaysOn
        >
          <AutocompleteInput optionText="company_name" source="company_name" />
        </ReferenceInput>
        <SelectInput
          source="name"
          label="Report"
          choices={[
            { id: "task_status_breakdown", name: "Task Status Counts" },
            { id: "daily_active_task_count", name: "Daily Active Task Counts" },
          ]}
        />
        <DateInput
          source="rundate_begin"
          showTime
          label="Earliest Rundate"
          formClassName={classes.inline}
        />
        <DateInput
          source="rundate_end"
          showTime
          label="Latest Rundate"
          formClassName={classes.inline}
        />
        <FormDataConsumer>
          {({ formData }) => {
            const hasFormErrors = Object.keys(checkForm(formData)).length > 0
            return (
              <Button
                onClick={() => onSearch(formData)}
                variant="contained"
                disabled={hasFormErrors}
              >
                <SearchIcon color="primary" /> Search
              </Button>
            )
          }}
        </FormDataConsumer>

        {reportResults && (
          <DailyTaskCounts
            results={reportResults.filter(
              (rr) => rr.name === "daily_active_task_count"
            )}
          />
        )}
        {reportResults &&
          reportResults.map((results, idx) => {
            return <ReportResult key={idx} reportResult={results} />
          })}
      </SimpleForm>
    </Card>
  )
}

const ReportResult = ({ reportResult }) => {
  switch (reportResult.name) {
    case "task_status_breakdown":
      return <TaskStatusBreakdown reportResult={reportResult} />
    default:
      return null
  }
}

ReportResult.propTypes = {
  reportResult: PropTypes.object,
}

const TaskStatusBreakdown = ({ reportResult }) => {
  const [data, setData] = useState(null)
  const ref = useRef(null)
  const dataProvider = useDataProvider()

  const margin = { left: 30, right: 150, top: 40, bottom: 20 }
  const height = 500

  useEffect(() => {
    // parse payload and lookup project names
    const payload = JSON.parse(reportResult.results)
    const ids = payload.map((perProject) => perProject.project_id)
    dataProvider.getMany("projects", { ids: ids }).then(({ data }) => {
      payload.forEach((perProject) => {
        data.forEach((project) => {
          if (project.id == perProject.project_id) {
            perProject.project_name = project.name
          }
        })
      })
      const d3styleData = payload.map((perProject) => {
        return {
          project_id: perProject.project_id,
          project_name: perProject.project_name,
          ...perProject.task_counts,
        }
      })
      setData(d3styleData)
    })
  }, [reportResult])

  useEffect(() => {
    if (!ref.current || data === null) {
      return
    }

    const width = ref.current && ref.current.getBoundingClientRect().width

    const projectNames = data.map((perProject) => perProject.project_name)

    // define x axis
    const x = d3
      .scaleBand()
      .domain(projectNames)
      .range([margin.left, width - margin.right])
      .padding(0.1)

    const xAxis = (g) =>
      g.attr("transform", `translate(0, ${height - margin.bottom})`).call(
        d3
          .axisBottom(x)
          .tickFormat((i) => i)
          .tickSizeOuter(0)
      )

    const taskStatuses = [
      "tba",
      "assigned",
      "in_progress",
      "done",
      "rework",
      "verified",
      "approved",
      "closed",
      "blocked",
      "archived",
    ]

    const maxY = data.reduce((max, perProject) => {
      let sum = 0
      for (const status of taskStatuses) {
        if (perProject[status]) {
          sum += perProject[status]
        }
      }
      return Math.max(max, sum)
    }, 0)

    // define y coordinate mapping
    var y = d3
      .scaleLinear()
      .domain([0, maxY])
      .nice()
      .range([height - margin.bottom, margin.top])

    // define x axis
    const yAxis = (g) =>
      g
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(y).ticks(null, "d"))
        .call((g) => g.select(".domain").remove())
        .call((g) =>
          g
            .append("text")
            .attr("x", -margin.left)
            .attr("y", 10)
            .attr("fill", "currentColor")
            .attr("text-anchor", "start")
            .text(data.y)
        )

    const d3svg = d3.select(ref.current)

    d3svg.selectAll("svg > *").remove()

    d3svg.append("g").call(xAxis)
    d3svg.append("g").call(yAxis)

    const dt = moment(reportResult.rundate).format("MMM DD YYYY")
    d3svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", 0 + margin.top / 2)
      .attr("text-anchor", "middle")
      .style("font-size", "16px")
      .style("text-decoration", "underline")
      .text(`Task Status Breakdown on ${dt}`)

    // color palette = one color per subgroup
    const color = d3.scaleOrdinal().domain(taskStatuses).range([
      "#003f5c", // tba
      "#2f4b7c", // assigned
      "#665191", // in_progress
      "#a05195", // done
      "#d45087", // rework
      "#f95d6a", // verified
      "#ff7c43", // approved
      "#000000", // closed
      "#FF0000", // blocked
      "#BBBBBB", // archived
      //"#ffa600", orange-ey unused
    ])

    const stackedData = d3.stack().keys(taskStatuses)(data)

    // Legend
    let legendY = 150
    for (let status of taskStatuses) {
      d3svg
        .append("circle")
        .attr("cx", width - 120)
        .attr("cy", legendY)
        .attr("r", 6)
        .style("fill", color(status))
      d3svg
        .append("text")
        .attr("x", width - 110)
        .attr("y", legendY)
        .text(status)
        .style("font-size", "15px")
        .attr("alignment-baseline", "middle")
      legendY += 30
    }

    // Fill bars based on data
    d3svg
      .append("g")
      .selectAll("g")
      .data(stackedData)
      .enter()
      .append("g")
      .attr("fill", (d) => color(d.key))
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("x", (d) => {
        return x(d.data.project_name)
      })
      .attr("y", (d) => {
        if (!isNaN(d[1])) {
          return y(d[1])
        }

        return 0
      })
      .attr("height", (d) => {
        if (isNaN(d[0]) || isNaN(d[1])) {
          return 0
        }

        return y(d[0]) - y(d[1])
      })
      .attr("width", x.bandwidth())
  }, [data])

  return (
    <svg
      ref={ref}
      style={{
        height: height,
        width: "100%",
        marginRight: margin.left,
        marginLeft: margin.left,
        marginTop: "20px",
      }}
    >
      <g className="plot-area" />
      <g className="x-axis" />
      <g className="y-axis" />
    </svg>
  )
}

TaskStatusBreakdown.propTypes = {
  reportResult: PropTypes.object,
}

const DailyTaskCounts = ({ results }) => {
  const ref = useD3(
    (svg) => {
      const height = 500
      const width = ref.current && ref.current.getBoundingClientRect().width

      const margin = { top: 20, right: 30, bottom: 30, left: 40 }

      let data = results.map((rr) => {
        const payload = JSON.parse(rr.results)
        const rundate = moment(rr.rundate)
        return {
          x: rundate.format("YYYY-MM-DD"),
          date: rundate,
          count: payload.reduce((count, perProject) => {
            count += perProject.task_count
            return count
          }, 0),
        }
      })
      data = sortBy(data, (datum) => datum.date)

      const x = d3
        .scaleBand()
        .domain(
          data.map((datum) => {
            return datum.x
          })
        )
        .rangeRound([margin.left, width - margin.right])
        .padding(0.1)

      const xAxis = (g) => {
        g.attr("transform", `translate(0,${height - margin.bottom})`).call(
          d3
            .axisBottom(x)
            .ticks(5)
            .tickFormat((i) => i)
            .tickSizeOuter(0)
        )
      }

      const y = d3
        .scaleLinear()
        .domain([0, d3.max(data, (datum) => datum.count)])
        .rangeRound([height - margin.bottom, margin.top])

      const yAxis = (g) => {
        g.attr("transform", `translate(${margin.left}, 0)`)
          .style("color", "steelblue")
          .call(d3.axisLeft(y).ticks(null, "s"))
          .call((g) => g.select(".domain").remove())
          .call((g) => {
            g.append("text")
              .attr("x", -margin.left)
              .attr("y", 10)
              .attr("fill", "currentColor")
              .attr("text-anchor", "start")
              .text(data.y)
          })
      }

      svg.select(".x-axis").call(xAxis)
      svg.select(".y-axis").call(yAxis)

      svg.selectAll("rect").remove()

      svg
        .selectAll("bar")
        .remove()
        .data(data)
        .enter()
        .append("rect")
        .style("fill", "steelblue")
        .attr("x", (datum) => {
          return x(datum.x)
        })
        .attr("width", x.bandwidth())
        .attr("y", (datum) => {
          return y(datum.count) - margin.bottom
        })
        .attr("height", (datum) => {
          return height - y(datum.count)
        })
    },
    [results.length]
  )

  if (results.length === 0) {
    return null
  }

  return (
    <svg
      ref={ref}
      style={{
        height: 500,
        width: "100%",
        marginRight: "0px",
        marginLeft: "0px",
      }}
    >
      <g className="plot-area" />
      <g className="x-axis" />
      <g className="y-axis" />
    </svg>
  )
}

DailyTaskCounts.propTypes = {
  results: PropTypes.array,
}
