import React from "react"
import PropTypes from "prop-types"
import { SelectInput, useEditContext } from "react-admin"

import { get, omit } from "lodash"

export const JobStatusInput = (props) => {
  const { record } = useEditContext()
  props = omit(props, ["hasShow", "hasList", "hasEdit", "hasCreate"])
  let choices = [
    { id: "open", name: "Open" },
    { id: "closed", name: "Closed" },
    { id: "preview", name: "Preview" },
  ]
  if (get(record, "status") === "canceled") {
    choices = [...choices, { id: "canceled", name: "Canceled" }]
  }
  return <SelectInput {...props} source="status" choices={choices} />
}

JobStatusInput.propTypes = {
  has_cancel: PropTypes.bool,
  props: PropTypes.object,
}
