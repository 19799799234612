import React from "react"

import { makeStyles } from "@mui/styles"

import { useInput } from "react-admin"

import { TextField } from "@mui/material"

const useStyles = makeStyles(() => {
  return {
    container: {
      maxWidth: "800px",
      marginBottom: "34px",
    },
    input: {
      "&>input": {
        paddingTop: "14px",
        paddingBottom: "14px",
      },
    },
  }
})

export const TimeInput = (props) => {
  const {
    field: { value, onChange },
  } = useInput(props)
  const classes = useStyles()
  return (
    <TextField
      id="time"
      type="time"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      variant="filled"
      InputLabelProps={{
        shrink: true,
      }}
      classes={{ input: classes.input }}
      inputProps={{
        step: 300, // 5 min
      }}
      InputProps={{
        className: classes.input,
      }}
    />
  )
}
