import React from "react"

import { SelectInput } from "react-admin"

export const TaskStatusInput = (props) => (
  <SelectInput
    {...props}
    choices={[
      { id: "tba", name: "TBA" },
      { id: "assigned", name: "Assigned" },
      { id: "in_progress", name: "In Progress" },
      { id: "done", name: "Done" },
      { id: "rework", name: "Rework" },
      { id: "verified", name: "Verified" },
      { id: "approved", name: "Approved" },
      { id: "closed", name: "Closed" },
      { id: "blocked", name: "Blocked" },
      { id: "archived", name: "Archived" },
    ]}
  />
)
