import React, { useState } from "react"

import moment from "moment"

import {
  DateInput,
  FormDataConsumer,
  AutocompleteArrayInput,
  SimpleForm,
  useDataProvider,
} from "react-admin"

import { ProjectInput } from "fields"

import { makeStyles } from "@mui/styles"
import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material"

const useStyles = makeStyles(() => ({
  tableRoot: {
    width: "100%",
  },
}))

export const ProjectDashboard = () => {
  const dataProvider = useDataProvider()
  const [dashboard, setDashboard] = useState({})
  const classes = useStyles()

  const onSave = (record) => {
    const day = moment(record.report_day).format("YYYY-MM-DD")
    const params = {
      report_day: day,
      complete_statuses: record.complete_statuses.join(","),
    }
    dataProvider
      .get(`/projects/${record.project_id}/dashboard`, params)
      .then((response) => {
        setDashboard(response.data)
      })
  }

  function validateForm(record) {
    return !(
      record.report_day &&
      record.complete_statuses.length &&
      record.project_id
    )
  }

  return (
    <Card>
      <SimpleForm
        toolbar={null}
        save={onSave}
        defaultValues={{ complete_statuses: ["approved", "done", "verified"] }}
      >
        <ProjectInput source="project_id" />
        <DateInput
          source="report_day"
          format={(v) => (v ? moment(v).format("YYYY-MM-DD") : null)}
          parse={(v) =>
            v ? moment.utc(v).set("hour", 12).toISOString() : null
          }
        />
        <AutocompleteArrayInput
          source="complete_statuses"
          choices={[
            { id: "approved", name: "Approved" },
            { id: "archived", name: "Archived" },
            { id: "assigned", name: "Assigned" },
            { id: "blocked", name: "Blocked" },
            { id: "closed", name: "Closed" },
            { id: "done", name: "Done" },
            { id: "in_progress", name: "In Progress" },
            { id: "rework", name: "Rework" },
            { id: "tba", name: "Not Assigned" },
            { id: "verified", name: "Verified" },
          ]}
        />

        <FormDataConsumer>
          {({ formData }) => (
            <Button
              disabled={validateForm(formData)}
              onClick={() => onSave(formData)}
              variant="contained"
            >
              Fetch Dashboard
            </Button>
          )}
        </FormDataConsumer>

        <p />

        {dashboard.project ? (
          <TableContainer
            classes={{ root: classes.tableRoot }}
            component={Paper}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Bucket</TableCell>
                  <TableCell>Counts</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {["daily", "weekly", "monthly", "yearly", "project"].map(
                  (bucketname, idx) => {
                    const bucket = dashboard[bucketname]
                    const mbegin = moment(bucket["date_begin"])
                    const mend = moment(bucket["date_end"])
                    return (
                      <TableRow key={`diff-${idx}`}>
                        <TableCell>{bucketname}</TableCell>
                        <TableCell>
                          <p>
                            <b>
                              {mbegin.format("YYYY-MM-DD HH:mm Z")} to{" "}
                              {mend.format("YYYY-MM-DD HH:mm Z")}
                            </b>
                          </p>
                          <ul>
                            <li>{bucket.completed} Complete</li>
                            {Object.hasOwnProperty.apply(bucket, [
                              "planned_completed",
                            ]) ? (
                              <li>
                                {bucket.planned_completed} Planned Complete
                              </li>
                            ) : null}
                            <li>{bucket.total} Total Tasks</li>
                          </ul>
                        </TableCell>
                      </TableRow>
                    )
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </SimpleForm>
    </Card>
  )
}
