import React, { cloneElement } from "react"
import PropTypes from "prop-types"

import {
  TopToolbar,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
} from "react-admin"

/* eslint-disable no-unused-vars */
export const CustomListActions = ({
  addlButtons,
  currentSort,
  className,
  resource,
  forceResource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  hasExport,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  hasShow,
  total,
  hasList,
  hasEdit,
  ...rest
}) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters &&
      cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    {hasCreate ? <CreateButton /> : null}
    {addlButtons}
    {hasExport ? (
      <ExportButton
        disabled={total === 0}
        resource={forceResource || resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        maxResults={maxResults}
      />
    ) : null}
  </TopToolbar>
)

CustomListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.object,
  resource: PropTypes.any,
  forceResource: PropTypes.string,
  hasCreate: PropTypes.bool,
  hasExport: PropTypes.bool,
  hasList: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasShow: PropTypes.bool,
  total: PropTypes.number,
  basePath: PropTypes.string,
  maxResults: PropTypes.number,
  showFilter: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  selectedIds: PropTypes.array,
  permanentFilter: PropTypes.object,
  onUnselectItems: PropTypes.func,
  currentSort: PropTypes.object,
  filterValues: PropTypes.object,
  displayedFilters: PropTypes.object,
  exporter: PropTypes.func,
  addlButtons: PropTypes.array,
}

CustomListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
}
