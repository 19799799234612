import React from "react"
import PropTypes from "prop-types"

import { get, sortBy } from "lodash"

import { useGetList } from "react-admin"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"

export const CompanyWorkerInput = ({ company_id, onSelectWorkerID }) => {
  const workerQuery = useGetList("companyworkers", {
    filter: { company_id: company_id, time_removed: null },
    pagination: { page: 1, perPage: 1000 },
    sort: ["id", "ASC"],
  })

  function handleChange(event) {
    if (onSelectWorkerID) {
      onSelectWorkerID(event.target.value)
    }
  }

  let workers = workerQuery.data || []
  workers = workers.filter(
    (w) => ["open", "invited"].indexOf(get(w, "workercard.status")) >= 0
  )
  workers = sortBy(workers, (w) => w.worker_full.full_name)

  return (
    <FormControl>
      <InputLabel id="demo-simple-select-label">Worker</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        onChange={handleChange}
        style={{ width: "400px", marginRight: "20px" }}
      >
        {(workers || []).map((worker, idx) => {
          return (
            <MenuItem key={idx} value={worker.worker_full.id}>
              {worker.worker_full.full_name}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

CompanyWorkerInput.propTypes = {
  company_id: PropTypes.string,
  onSelectWorkerID: PropTypes.func,
}
