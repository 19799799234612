import React from "react"
import PropTypes from "prop-types"

import {
  Datagrid,
  List,
  Loading,
  NumberField,
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceField,
  TextField,
  useShowContext,
} from "react-admin"

import { makeStyles } from "@mui/styles"

import { AuditList } from "audits"

const useStyles = makeStyles({
  inline: {
    display: "inline-flex",
    marginRight: "1rem",
  },
})

export const WorkerShow = (props) => {
  const styles = useStyles()

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <ReferenceField source="company_id" reference="companies">
            <TextField source="company_name" />
          </ReferenceField>
          <TextField source="full_name" />
          <TextField source="trade" />
          <TextField source="job_location" />
          <NumberField source="headcount" />
          <NumberField source="pay_low" className={styles.inline} />
          <NumberField source="pay_high" className={styles.inline} />
          <TextField source="job_location" />
        </Tab>
        <Tab label="Groups">
          <MemberOfGroupsList />
        </Tab>
        <Tab label="Audits">
          <AuditList
            source_table="worker"
            hide_fields={["time_created", "time_updated"]}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

WorkerShow.propTypes = {
  record: PropTypes.object,
}

const MemberOfGroupsList = () => {
  const showContext = useShowContext()
  const record = showContext.record

  if (!record || !record.id) {
    return <Loading />
  }

  return (
    <List
      hasCreate={false}
      hasEdit={false}
      hasList={false}
      hasShow={false}
      resource="groups"
      filter={{ member_id: record.id, time_removed: null }}
    >
      <Datagrid>
        <TextField source="name" />
      </Datagrid>
    </List>
  )
}
