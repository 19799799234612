import React from "react"
import PropTypes from "prop-types"

import {
  DateTimeInput,
  Edit,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  maxLength,
  number,
  required,
} from "react-admin"

import { SaveButton, Toolbar } from "react-admin"

import { makeStyles } from "@mui/styles"

import {
  AvailabilityInput,
  BenefitsInput,
  CompanyInput,
  JobFlyerURLField,
  JobStatusInput,
  JobTypeInput,
  PayInput,
  TradeInput,
  UnionArrayInput,
  WorkingDaysInput,
  WorkerSkillsInput,
  YearsOfXPInput,
} from "../fields"
import { validateJobAttributes } from "./job_common"

const useStyles = makeStyles({
  inline: {
    display: "inline-flex",
    marginRight: "1rem",
  },
  jobflyer: {
    marginBottom: "20px",
    fontSize: "24px",
    marginLeft: "10px",
  },
  halfWidth: {
    width: "40%",
    display: "inline-flex",
    marginRight: "1rem",
  },
  bMargin: {
    marginBottom: "16px",
  },
})

const EditToolbar = (props) => (
  <Toolbar {...props} margin="dense">
    <SaveButton
      style={{ marginRight: "20px" }}
      label="Save & leave"
      type="button"
    />
  </Toolbar>
)

export const JobEdit = (props) => {
  const classes = useStyles()
  let localzone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return (
    <Edit {...props}>
      <SimpleForm
        toolbar={<EditToolbar />}
        defaultValues={{
          pay_type: "hourly_range",
          pay_currency: "USD",
          soft_skills: [],
          trade_skills: [],
        }}
        validate={validateJobAttributes}
      >
        <JobFlyerURLField className={classes.jobflyer} source="id" />
        <ReferenceInput
          source="company_id"
          reference="companies"
          validate={required()}
          allowEmpty
        >
          <CompanyInput />
        </ReferenceInput>

        <JobTypeInput source="type" />

        <TextInput source="job_title" validate={required()} fullWidth />

        <JobStatusInput {...props} />

        <FormDataConsumer>
          {({ formData }) => {
            let validate = maxLength(8000)
            if (formData.type !== "joblet") {
              validate = [required(), maxLength(8000)]
            }
            return (
              <TextInput
                source="job_description"
                multiline
                fullWidth
                validate={validate}
              />
            )
          }}
        </FormDataConsumer>

        <TradeInput
          source="trade"
          className={classes.halfWidth}
          validate={required()}
        />

        <WorkerSkillsInput
          formClassName={classes.bMargin}
          source="trade_skills"
          multiple
          soft_skills={false}
          classes={{ root: classes.halfWidth }}
        />

        <WorkerSkillsInput
          formClassName={classes.bMargin}
          source="soft_skills"
          multiple
          soft_skills
          classes={{ root: classes.halfWidth }}
        />

        <NumberInput
          validate={number()}
          source="filled"
          format={(v) => (v ? v : 0)}
          defaultValue={0}
          formClassName={classes.inline}
        />
        <NumberInput
          validate={number()}
          source="headcount"
          format={(v) => (v ? v : 1)}
          formClassName={classes.inline}
          styles={{ "margin-right": 0 }}
        />

        <UnionArrayInput source="union_info" fullWidth />

        <BenefitsInput fullWidth source="benefits" />

        <PayInput />

        <TextInput
          validate={required()}
          source="job_location"
          className={classes.halfWidth}
        />

        <YearsOfXPInput source="years_of_xp" validate={required()} />
        <AvailabilityInput source="availability" />

        <FormDataConsumer>
          {({ formData }) => {
            let validate
            if (
              formData.availability === "project_by_project" ||
              formData.job_type === "joblet"
            ) {
              validate = required()
            }
            return (
              <div>
                <DateTimeInput
                  source="datetime_start"
                  className={classes.halfWidth}
                  label={`Job Start Time in ${localzone}`}
                  validate={validate}
                />
                <DateTimeInput
                  source="datetime_end"
                  className={classes.halfWidth}
                  label={`Job End Time in ${localzone}`}
                  validate={validate}
                />
              </div>
            )
          }}
        </FormDataConsumer>
        <WorkingDaysInput source="working_days" fullWidth />
      </SimpleForm>
    </Edit>
  )
}

JobEdit.propTypes = {
  record: PropTypes.object,
}
