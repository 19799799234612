import React from "react"

import {
  ReferenceInput,
  DateTimeInput,
  FormDataConsumer,
  AutocompleteInput,
  SimpleForm,
  SelectInput,
  useDataProvider,
  useNotify,
} from "react-admin"

import { Button, Card } from "@mui/material"
import { makeStyles } from "@mui/styles"

import AddIcon from "@mui/icons-material/Add"

const useStyles = makeStyles((theme) => ({
  inline: {
    display: "inline-flex",
    marginRight: theme.spacing(1),
  },
}))

export const RequestRemoteReports = () => {
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const handleCreate = (record) => {
    dataProvider
      .post(`/companies/${record.company_id}/run_report`, {
        name: record.name,
        rundate: record.rundate.toISOString(),
      })
      .then(() => notify("Report initiated"))
  }

  const checkForm = (record) => {
    const errors = {}
    if (!record.company_id) {
      errors.company_id = "Company is required"
    }
    if (!record.rundate) {
      errors.company_id = "Rundate is required"
    }
    return errors
  }

  const classes = useStyles()
  return (
    <Card>
      <SimpleForm toolbar={null}>
        <ReferenceInput
          fullWidth
          label="Company"
          source="company_id"
          reference="companies"
          sort={{ field: "company_name", order: "ASC" }}
          filterToQuery={(searchText) => ({ q: searchText })}
          alwaysOn
        >
          <AutocompleteInput optionText="company_name" source="company_name" />
        </ReferenceInput>
        <SelectInput
          source="name"
          label="Report"
          choices={[
            { id: "task_status_breakdown", name: "Task Status Counts" },
            { id: "daily_active_task_count", name: "Daily Active Task Counts" },
            {
              id: "daily_past_due_task_count",
              name: "Daily Past Due Task Count",
            },
            {
              id: "daily_active_worker_count",
              name: "Daily Active Worker Count",
            },
            {
              id: "daily_worker_task_load",
              name: "Daily Worker Task Load",
            },
            {
              id: "weekly_worker_task_load",
              name: "Weekly Worker Task Load",
            },
            { id: "daily_issues_count", name: "Daily Issues Count" },
            { id: "project_stats", name: "Project Stats" },
          ]}
        />
        <DateTimeInput
          source="rundate"
          showTime
          label="Rundate"
          formClassName={classes.inline}
        />
        <FormDataConsumer formClassName={classes.inline}>
          {({ formData }) => {
            return (
              <span className={classes.inline}>
                {" "}
                {formData.rundate &&
                  `${formData.rundate.toISOString()} in UTC timezone`}{" "}
              </span>
            )
          }}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }) => {
            const hasFormErrors = Object.keys(checkForm(formData)).length > 0
            return (
              <Button
                onClick={() => handleCreate(formData)}
                variant="contained"
                disabled={hasFormErrors}
              >
                <AddIcon color="primary" /> Create
              </Button>
            )
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Card>
  )
}
