export const validateJobAttributes = (formData) => {
  const errors = {}

  if (
    formData.pay_type === "hourly_range" ||
    formData.pay_type === "salary_range"
  ) {
    if (formData.pay_low > formData.pay_high) {
      errors.pay_low = "Minimum must be lower than maximum"
    }
  }

  return errors
}
