import React, { useRef } from "react"
import PropTypes from "prop-types"
import Config from "../config"

import { Button, useNotify } from "react-admin"

export const WorkerCardURLField = ({ record, source, className, anchor }) => {
  if (!record) {
    return null
  }

  let name
  if (anchor && record[anchor]) {
    name = record[anchor]
  } else {
    name = [record.first_name, record.last_name].join(" ")
    if (name === null || name.match(/^ *$/) !== null) {
      name = "(empty name)"
    }
  }
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className={className}
      href={`${Config.jbURL}/workercard/${record[source]}`}
    >
      {name}
    </a>
  )
}

WorkerCardURLField.propTypes = {
  anchor: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
  className: PropTypes.string,
}

export const WorkerCardURLCopyButton = ({ record, source, anchor }) => {
  const textAreaRef = useRef(null)
  const notify = useNotify()

  if (!record) {
    return null
  }

  let name
  if (anchor && record[anchor]) {
    name = record[anchor]
  } else {
    name = [record.first_name, record.last_name].join(" ")
    if (name === null || name.match(/^ *$/) !== null) {
      name = "(empty name)"
    }
  }

  const url = `${Config.jbURL}/workercard/${record[source]}`

  function copyToClipboard(e) {
    textAreaRef.current.select()
    document.execCommand("copy")
    e.target.focus()
    notify("URL Copied!")
    e.stopPropagation()
  }

  return (
    <>
      <textarea
        ref={textAreaRef}
        style={{ position: "absolute", left: "-50000px" }}
        value={url}
        onChange={() => null}
      />
      <Button label="Copy URL" onClick={copyToClipboard}></Button>
    </>
  )
}

WorkerCardURLCopyButton.propTypes = {
  anchor: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
}
