import React from "react"
import PropTypes from "prop-types"

import { AutocompleteInput } from "react-admin"

import timezones from "timezones-list"
import { sortBy } from "lodash"

export const TimezoneInput = () => {
  let choices = timezones.map((tz) => {
    return {
      id: tz.tzCode,
      name: tz.label,
      nameLower: tz.label.toLowerCase(),
    }
  })
  choices = sortBy(choices, "name")

  return (
    <AutocompleteInput
      source="timezone"
      choices={choices}
      fullWidth
      noOptionsText="No timezone names match"
      shouldRenderSuggestions={(v) => v.trim().length > 2}
    />
  )
}

TimezoneInput.propTypes = {
  source: PropTypes.string,
}
