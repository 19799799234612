import React from "react"
import { Datagrid, List, ReferenceField, TextField } from "react-admin"

import { WorkerCardURLField } from "fields"

import { keyBy } from "lodash"

const exporter = async (records, fetchRelatedRecords) => {
  const workers = await fetchRelatedRecords(records, "worker_id", "workers")
  const jobs = await fetchRelatedRecords(records, "job_id", "jobs")

  const wmap = keyBy(workers)
  for (let r in records) {
    r.worker = wmap[r.worker_id]
  }

  const jmap = keyBy(jobs)
  for (let r in records) {
    r.job = jmap[r.job_id]
  }

  console.log("job_applications", records)
}

export const CompanyApplicantList = (props) => {
  return (
    <List
      {...props}
      sort={{ field: "time_created", order: "DESC" }}
      exporter={exporter}
    >
      <Datagrid>
        <ReferenceField label="Job Title" source="job_id" reference="jobs">
          <TextField source="job_title" label="Job Title" />
        </ReferenceField>
        <ReferenceField label="Location" source="job_id" reference="jobs">
          <TextField source="job_location" />
        </ReferenceField>
        <TextField source="application_status" />
        <ReferenceField
          label="Worker URL"
          source="worker_id"
          reference="workers"
        >
          <WorkerCardURLField source="id" />
        </ReferenceField>
        <ReferenceField label="Level" source="worker_id" reference="workers">
          <TextField source="profile_level" />
        </ReferenceField>
        <ReferenceField
          label="Worker Name"
          source="worker_id"
          reference="workers"
        >
          <TextField source="full_name" />
        </ReferenceField>
      </Datagrid>
    </List>
  )
}
