import React from "react"
import PropTypes from "prop-types"

import { parse } from "query-string"

import {
  Create,
  AutocompleteInput,
  DateTimeInput,
  FormDataConsumer,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  required,
  maxLength,
} from "react-admin"
import { makeStyles } from "@mui/styles"

import { omit } from "lodash"

import { dataProvider } from "../data_provider"
import {
  AddressInput,
  AutoAssignTradeSkillsInput,
  AvailabilityInput,
  BenefitsInput,
  JobTypeInput,
  PayInput,
  SkillChooserInput,
  TradeInput,
  UnionArrayInput,
  YearsOfXPInput,
} from "../fields"

import { validateJobAttributes } from "./job_common"

const useStyles = makeStyles({
  inline: {
    display: "inline-flex",
    marginRight: "1rem",
  },
  halfWidth: {
    width: "40%",
    display: "inline-flex",
    marginRight: "1rem",
  },
  fullWidth: {
    width: "100%",
  },
})

const DateRangeInputs = () => {
  const classes = useStyles()
  const localzone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return (
    <FormDataConsumer>
      {({ formData }) => {
        let validate
        if (
          formData.availability === "project_by_project" ||
          formData.job_type === "joblet"
        ) {
          validate = required()
        }
        return (
          <div className={classes.fullWidth}>
            <DateTimeInput
              source="datetime_start"
              className={classes.halfWidth}
              label={`Job Start Time in ${localzone}`}
              validate={validate}
            />
            <DateTimeInput
              source="datetime_end"
              className={classes.halfWidth}
              label={`Job End Time in ${localzone}`}
              validate={validate}
            />
          </div>
        )
      }}
    </FormDataConsumer>
  )
}

const FullJobForm = (props) => {
  props = omit(props, "fullWidth")
  return (
    <>
      <TextInput
        source="job_title"
        validate={[required(), maxLength(1000)]}
        fullWidth
      />
      <TextInput
        {...props}
        source="job_description"
        multiline
        fullWidth
        validate={[required(), maxLength(8000)]}
      />
      <TradeInput {...props} source="trade" validate={required()} fullWidth />
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <AutoAssignTradeSkillsInput
            {...rest}
            trade={formData.trade}
            fullWidth
          />
        )}
      </FormDataConsumer>
      <ReferenceArrayInput
        {...props}
        label="Soft Skills"
        source="soft_skills"
        reference="approvedskills"
        allowEmpty
        fullWidth
        perPage={500}
        filter={{ category: "softskills" }}
        sort={{ field: "category", order: "ASC" }}
      >
        <SkillChooserInput />
      </ReferenceArrayInput>
      <UnionArrayInput {...props} source="union_info" fullWidth />
      <NumberInput
        {...props}
        style={{ marginRight: "16px" }}
        validate={required()}
        source="headcount"
      />
      <PayInput {...props} />
      <BenefitsInput {...props} fullWidth source="benefits" />
      <AddressInput
        {...props}
        source="job_location"
        fullWidth
        validate={required()}
        label="Address"
      />
      <YearsOfXPInput
        {...props}
        style={{ marginRight: "16px" }}
        source="years_of_xp"
        validate={required()}
      />
      <AvailabilityInput
        {...props}
        source="availability"
        validate={required()}
      />
      <DateRangeInputs {...props} />
    </>
  )
}

const JobletForm = (props) => {
  return (
    <>
      <TextInput
        {...props}
        source="job_title"
        validate={[required(), maxLength(1000)]}
        fullWidth
      />
      <TradeInput source="trade" validate={required()} fullWidth />
      <FormDataConsumer>
        {({ formData }) => (
          <AutoAssignTradeSkillsInput trade={formData.trade} fullWidth />
        )}
      </FormDataConsumer>
      <NumberInput
        style={{ marginRight: "16px" }}
        validate={required()}
        source="headcount"
      />
      <PayInput />
      <AddressInput
        {...props}
        source="job_location"
        fullWidth
        validate={required()}
        label="Address"
      />
      <YearsOfXPInput
        style={{ marginRight: "16px" }}
        source="years_of_xp"
        validate={required()}
      />
      <AvailabilityInput source="availability" validate={required()} />

      <DateRangeInputs />
    </>
  )
}

// eslint-disable-next-line no-unused-vars
const JobForm = ({ hasList, basePath, ...props }) => {
  return (
    <SimpleForm {...props} validate={validateJobAttributes}>
      <ReferenceInput
        allowEmpty={true}
        filterToQuery={(searchText) => ({ q: searchText })}
        fullWidth
        label="Company"
        reference="companies"
        sort={{ field: "company_name", order: "ASC" }}
        source="company_id"
        validate={required()}
      >
        <AutocompleteInput optionText="company_name" source="company_name" />
      </ReferenceInput>
      <JobTypeInput source="type" />
      <FormDataConsumer fullWidth>
        {({ formData, ...rest }) => {
          if (formData.type === "full_job") {
            return <FullJobForm {...rest} />
          } else if (formData.type === "joblet") {
            return <JobletForm {...rest} />
          }
          return null
        }}
      </FormDataConsumer>
    </SimpleForm>
  )
}
JobForm.propTypes = {
  hasList: PropTypes.bool,
  basePath: PropTypes.string,
  record: PropTypes.object,
}

const onSave = async (record) => {
  if (record.trade_skills && record.trade_skills.length) {
    const skillsResp = await dataProvider.getMany("approvedskills", {
      ids: record.trade_skills,
    })
    record = { ...record, trade_skills: skillsResp.data }
  }

  if (record.soft_skills && record.soft_skills.length) {
    const softSkillsResp = await dataProvider.getMany("approvedskills", {
      ids: record.soft_skills,
    })
    record = { ...record, soft_skills: softSkillsResp.data }
  }

  return {
    ...record,
    status: "preview",
  }
}

export const JobCreate = ({ location, ...props }) => {
  const { company_id: company_id_str } = parse(location.search)

  return (
    <Create {...props} transform={onSave}>
      <JobForm
        defaultValues={{
          filled: 0,
          headcount: 1,
          pay_currency: "USD",
          type: "full_job",
          company_id: company_id_str,
        }}
      />
    </Create>
  )
}

JobCreate.propTypes = {
  location: PropTypes.object,
}
