import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import { makeStyles } from "@mui/styles"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import { useInput } from "react-admin"
import { find } from "lodash"

const basicOptions = [
  "webapp",
  "mobile",
  "linkedin",
  "indeed",
  "ihire",
  "craigslist",
  "facebook",
]

const useStyles = makeStyles({
  quarterWidth: {
    width: "25%",
    display: "inline-flex",
  },
})

// eslint-disable-next-line no-unused-vars
export const WorkerSourceTypeInput = ({ basePath, ...props }) => {
  const [options, setOptions] = useState(basicOptions)
  const {
    field: { value, onChange },
    fieldState: { isTouched, error },
  } = useInput({ basePath, ...props })

  const selectedVal = find(options, value)
  useEffect(() => {
    if (!selectedVal) {
      setOptions([value, ...basicOptions])
    }
  }, [selectedVal, value])

  function filterChoices(options, params) {
    if (params && params.inputValue) {
      const filterLower = params.inputValue.toLowerCase()
      let matches = options.filter((option) => {
        return option.toLowerCase().indexOf(filterLower) >= 0
      })
      for (let match of matches) {
        if (match.toLowerCase() === filterLower) {
          return basicOptions
        }
      }
      return [params.inputValue, ...basicOptions]
    }
    return basicOptions
  }

  const classes = useStyles()
  return (
    <Autocomplete
      className={classes.quarterWidth}
      {...props}
      name="worker_source_input"
      getOptionLabel={(option) => option}
      value={value || null}
      variant="standard"
      error={isTouched && error ? error : undefined}
      options={options}
      filterOptions={filterChoices}
      onChange={(event, newValue) => {
        if (options.filter((opt) => opt === newValue).length === 0) {
          setOptions([newValue, ...options])
        }
        onChange(newValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label="Worker Source Type"
          placeholder="Mobile"
        />
      )}
      style={{ marginBottom: "1.5rem" }}
    />
  )
}

WorkerSourceTypeInput.propTypes = {
  basePath: PropTypes.string,
}
