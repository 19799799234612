import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import {
  ArrayInput,
  BooleanInput,
  Edit,
  FileField,
  FileInput,
  FormTab,
  Link,
  ImageInput,
  ImageField,
  SaveButton,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  Toolbar,
  useEditContext,
  useNotify,
} from "react-admin"
import { makeStyles } from "@mui/styles"
import { Button, ButtonGroup } from "@mui/material"

import {
  AvailabilityInput,
  ConstructionTypesInput,
  DesiredTradesInput,
  WorkerCardURLField,
  WorkerSkillsInput,
  YearsOfXPInput,
  WorkerSourceTypeInput,
  TradeInput,
} from "fields"
import { dataProvider } from "data_provider"
import {
  phoneValidate,
  CertificationsInput,
  WorkExperiencesForm,
} from "./worker_shared"

const useStyles = makeStyles({
  table_cell: {
    whiteSpace: "nowrap",
  },
  inline: {
    display: "inline-flex",
    marginRight: "1rem",
  },
  workercard: {
    fontSize: "24px",
    marginBottom: "20px",
    marginLeft: "10px",
    display: "inline-block",
  },
  companyLink: {
    fontSize: "18px",
    marginBottom: "20px",
    display: "inline-block",
    marginLeft: "10px",
  },
  bottomSpace: {
    marginBottom: "20px",
  },
})

function validateStatus(origRecord) {
  return (nextStatus) => {
    if (origRecord.status != nextStatus) {
      if (nextStatus === "closed") {
        return "Use Delete button to close workers"
      } else if (origRecord.status === "deleted" && nextStatus !== "invited") {
        return "Reopening worker by setting status to 'Invited'"
      }
    }
    return undefined
  }
}

const onSaveTransform = async (record) => {
  if (record.work_experiences) {
    // make sure experiences are properly ordered
    record.work_experiences.forEach((exp, idx) => {
      exp.sort_order = idx
      exp.location = exp.location || ""
      if (exp.id === null) {
        delete exp.id
      }
      if (exp.verification_status === null) {
        exp.verification_status = "unverified"
      }
      if (exp.end_date === "") {
        exp.end_date = null
      }
    })
  }

  if (record.certifications) {
    record.certifications.forEach((cert) => {
      if (cert.issued_date === "") {
        cert.issued_date = null
      }
      if (cert.expiration_date === "") {
        cert.expiration_date = null
      }
    })
  }

  if (record.profile_pic && record.profile_pic.rawFile) {
    let profileData = new FormData()
    profileData.append(
      "profilepic",
      record.profile_pic.rawFile,
      record.profile_pic.filename
    )
    profileData.append(
      "profilepic_original",
      record.profile_pic.rawFile,
      record.profile_pic.filename
    )
    delete record.profile_pic
    delete record.profile_pic_url
    delete record.profile_pic_orig_url
    await dataProvider.uploadWorkerImage(record.id, profileData)
  }

  if (record.resume && record.resume.rawFile) {
    const url = await dataProvider.uploadResume(record.id, record.resume)
    record.resume_url = url
  } else if (record.resume_url && !record.resume) {
    record.resume_url = null
  }
  delete record.resume

  if (!record.trade_skills) {
    record.trade_skills = []
  } else {
    record.trade_skills.forEach((skill) => {
      // API will mark the skill as custom_new without worker_id
      skill.worker_id = record.id
      // react-hook-form will set {id: null} if a skill is removed and added again
      // which connexion ID decoding doesn't like, so remove
      if (skill.id === null) {
        delete skill.id
      }
    })
  }

  if (!record.years_of_xp) {
    delete record.years_of_xp
  }

  record.agree_digest_sms = !!record.agree_digest_sms
  record.agree_privacy_policy = !!record.agree_privacy_policy

  record.trade_skills.forEach((skill) => {
    // Assure there's a review status.  GOTTA FIX SKILLS
    skill.review_status = skill.review_status || "approved"
  })

  if (record.profile_level_input !== record.profile_level) {
    record.profile_level = record.profile_level_input
  } else {
    delete record.profile_level
  }
  delete record.profile_level_input

  return record
}

export const WorkerEdit = (props) => {
  return (
    <Edit {...props} transform={onSaveTransform}>
      <WorkerEditForm />
    </Edit>
  )
}

const WorkerEditToolbar = (props) => {
  return (
    <Toolbar {...props} margin="dense">
      <SaveButton style={{ marginRight: "20px" }} type="button" />
    </Toolbar>
  )
}

const WorkerEditForm = () => {
  const { record } = useEditContext()
  const [company, setCompany] = useState(null)
  const [loading, setLoading] = useState(false)
  const notify = useNotify()

  async function onUnreadButtonPress() {
    return dataProvider
      .clearUnread(record.id)
      .then((resp) => {
        if (resp.status == 200) {
          notify("All Chat's marked read")
        } else {
          notify("Chat Unread count update failed", "warning")
        }
      })
      .catch((err) => {
        notify("Chat Unread count update failed: " + err, "warning")
      })
  }

  useEffect(() => {
    if (record.id) {
      if (record.profile_level) {
        record.profile_level_input = record.profile_level
      }
      if (record.resume_url) {
        record.resume = {
          url: record.resume_url,
          title: record.resume_url.substring(
            record.resume_url.lastIndexOf("/") + 1
          ),
        }
      }
      setLoading(true)
      dataProvider
        .getCompaniesByWorkerID(record.id)
        .then(({ json }) => {
          if (json.length === 1) {
            setCompany(json[0])
          }
        })
        .finally(() => setLoading(false))
    }
  }, [record])

  const classes = useStyles()
  return (
    <TabbedForm record={record} toolbar={<WorkerEditToolbar />} margin="none">
      <FormTab label="Account Info">
        <WorkerCardURLField
          label="Worker Card"
          className={classes.workercard}
          source="id"
        />

        {!loading && company !== null ? (
          <Link className={classes.companyLink} to={`/companies/${company.id}`}>
            {company.company_name}
          </Link>
        ) : null}

        <ImageField source="profile_pic_url" title="Current Profile Image" />
        <ImageInput
          source="profile_pic"
          label="Set New Profile Pic"
          accept="image/*"
        >
          <ImageField source="src" title="filename" />
        </ImageInput>

        <TextInput source="mobile_number" validate={phoneValidate} />

        <SelectInput
          source="status"
          validate={validateStatus(record)}
          choices={[
            { id: "invited", name: "Invited" },
            { id: "anonymous", name: "Anonymous" },
            { id: "open", name: "Open" },
            { id: "closed", name: "Closed" },
          ]}
        />

        <TextInput
          source="full_name"
          formClassName={classes.inline}
          fullWidth
        />

        <TextInput source="address_txt" label="Location" fullWidth />
        <TextInput source="email" label="Email" fullWidth />

        <WorkerSourceTypeInput source="source_type" />
        <TextInput source="source" label="Worker Source" fullWidth />

        <TextInput source="email_status" label="Email Status" disabled />
        <TextInput source="union_name" fullWidth />
        <TextInput fullWidth source="resume_url" label="Resume URL" />
        <TextInput fullWidth source="linkedin_url" label="LinkedIn URL" />
        <TextInput fullWidth source="indeed_url" label="Indeed URL" />
        <ButtonGroup
          onClick={onUnreadButtonPress}
          color="primary"
          variant="contained"
          aria-label="outlined primary button group"
          className={classes.buttonGroup}
        >
          <Button>Clear Chat Unread Count</Button>
        </ButtonGroup>
      </FormTab>

      <FormTab label="Account Management">
        <WorkerCardURLField
          label="Worker Card"
          className={classes.workercard}
          source="id"
        />

        <ArrayInput source="feature_whitelist">
          <SimpleFormIterator>
            <SelectInput
              label="Feature"
              choices={[
                { id: "crews", name: "Crews" },
                { id: "foreman", name: "Foreman" },
                { id: "marketplace", name: "Marketplace" },
                { id: "notifybot", name: "CORE Chat" },
                { id: "optimizer", name: "Optimizer" },
              ]}
            />
          </SimpleFormIterator>
        </ArrayInput>

        <SelectInput
          source="language_pref"
          choices={[
            { id: "en_US", name: "English (US)" },
            { id: "pt_BR", name: "Portuguese (BR)" },
            { id: "es_US", name: "Spanish (US)" },
            { id: "ja_JP", name: "Japanese (JP)" },
          ]}
        />
        <SelectInput
          source="locale"
          choices={[
            { id: "US", name: "US" },
            { id: "BR", name: "BR" },
          ]}
        />
        <BooleanInput
          source="agree_privacy_policy"
          formClassName={classes.inline}
        />
        <BooleanInput
          source="agree_digest_sms"
          formClassName={classes.inline}
        />
      </FormTab>

      <FormTab label="Work Experience">
        <WorkerCardURLField
          label="Worker Card"
          className={classes.workercard}
          source="id"
        />

        <FileInput source="resume" label="Resume">
          <FileField source="url" title="title" />
        </FileInput>

        <TextInput multiline fullWidth source="hireme_pitch" label="Pitch" />

        <TradeInput source="trade" />

        <h3>Job Preferences</h3>
        <TextInput
          source="job_preferences.travel_distance"
          label="Travel Distance"
        />
        <DesiredTradesInput
          source="job_preferences.desired_trades"
          label="Desired Trades"
          fullWidth
          validate={(buggy_desired_trades, allFields) => {
            const trades = allFields.job_preferences.desired_trades
            return trades && trades.length > 3
              ? "Up to 3 desired trades permitted"
              : undefined
          }}
        />

        <TextInput source="hourly_rate_low" formClassName={classes.inline} />

        <YearsOfXPInput source="years_of_xp" formClassName={classes.inline} />

        <AvailabilityInput
          source="availability"
          formClassName={classes.inline}
        />

        <ConstructionTypesInput
          source="job_preferences.construction_types"
          fullWidth
          label="Construction Types"
        />

        <h3>Work Experiences</h3>

        <ArrayInput
          source="work_experiences"
          className={classes.table_cell}
          label=""
        >
          <WorkExperiencesForm />
        </ArrayInput>

        <h3>Certifications</h3>

        <CertificationsInput />

        <h3>Skills</h3>

        <WorkerSkillsInput
          classes={{ root: classes.bottomSpace }}
          source="trade_skills"
          multiple
          fullWidth
        />

        <SelectInput
          source="profile_level_input"
          choices={[
            { id: "anonymous", name: "Anonymous" },
            { id: "empty", name: "Empty" },
            { id: "signed_up", name: "Signed Up" },
            { id: "match", name: "Match" },
            { id: "complete", name: "Complete" },
          ]}
        />
      </FormTab>
    </TabbedForm>
  )
}

WorkerEditForm.propTypes = {
  save: PropTypes.func,
}
