import React from "react"
import PropTypes from "prop-types"
import Config from "./config"

import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  FormDataConsumer,
  List,
  ReferenceField,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  SelectInput,
  UrlField,
  useDataProvider,
  useInput,
  useRecordContext,
} from "react-admin"

import { CampaignWorkersTable } from "./campaign_workers_edit"

import { CustomListActions } from "./list_actions"
import { Button } from "@mui/material"
import { makeStyles } from "@mui/styles"

const CampaignFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Status"
      source="status"
      alwaysOn
      choices={[
        { id: "created", name: "Created" },
        { id: "removed", name: "Removed" },
        { id: "published", name: "Published" },
        { id: "sent", name: "Sent" },
      ]}
    />
  </Filter>
)
const WorkerCardField = ({ record, source }) => {
  const name = record[source]
  return (
    <a
      href={`${Config.jbURL}/workercard/${record[source]}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {name}
    </a>
  )
}

WorkerCardField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
}

const JobFlyerField = ({ source }) => {
  const record = useRecordContext()
  const name = record[source]
  return (
    <a
      href={`${Config.jbURL}/jobflyer/${record[source]}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {name}
    </a>
  )
}

JobFlyerField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
}

export const CampaignList = ({ hasEdit, hasList, ...props }) => (
  <List
    {...props}
    hasEdit={hasEdit}
    hasList={hasList}
    filters={<CampaignFilter />}
    actions={<CustomListActions {...props} hasCreate={true} />}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="type" />
      <TextField label="Name" source="name" />
      <JobFlyerField source="job_id" />
      <TextField source="status" />
      <DateField source="time_created" showTime />
      <DateField source="time_published" showTime />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

CampaignList.propTypes = {
  hasEdit: PropTypes.bool,
  hasList: PropTypes.bool,
}

const CampaignForm = (props) => {
  return (
    <SimpleForm {...props}>
      <SelectInput
        label="Type"
        source="type"
        parse={(value) => value}
        choices={[
          { id: "sms", name: "SMS" },
          { id: "push", name: "Push" },
        ]}
      />
      <ReferenceField source="job_id" reference="jobs">
        <TextField source="job_title" />
      </ReferenceField>
      <SelectInput
        label="Status"
        source="status"
        parse={(value) => value}
        choices={[
          { id: "created", name: "Created" },
          { id: "removed", name: "Removed" },
          { id: "published", name: "Published" },
          { id: "sent", name: "Sent" },
        ]}
      />
      <TextInput label="Name" source="name" parse={(value) => value} />

      <h3>Push Payload</h3>
      <TextInput label="Title" source="payload.title" />
      <TextInput multiline label="Message" source="payload.body" />
      <SelectInput
        label="Anchor"
        source="payload.anchor"
        parse={(value) => value}
        choices={[
          { id: "my_tasks", name: "My Tasks" },
          { id: "project_selector", name: "Project Selector" },
          { id: "project_task_list", name: "Project Task List" },
          { id: "chat_list", name: "Chat List" },
          { id: "crew_list", name: "Crew List" },
          { id: "profile", name: "Profile" },
          { id: "site_link", name: "Site Link" },
          { id: "schedule", name: "Schedule" },
        ]}
      />
      <TextInput label="Link" source="payload.link" />
      <CampaignWorkerAdd source="campaign_workers" />
      <CampaignWorkersTable source="campaign_workers" />
    </SimpleForm>
  )
}

CampaignForm.propTypes = {
  record: PropTypes.object,
}

const useStyles = makeStyles(() => ({
  workerIDList: {
    width: "50%",
  },
  addWorkersButton: {
    marginTop: "10px",
    marginLeft: "20px",
  },
}))

const CampaignWorkerAdd = (props) => {
  const {
    field: { onChange },
  } = useInput(props)
  const dataProvider = useDataProvider()

  const copyWorkers = (formData) => {
    combineWorkers(dataProvider, formData).then((combined) => {
      if (combined) {
        onChange(combined)
      }
    })
  }

  const classes = useStyles()
  return (
    <>
      <TextInput
        label="Newline separated Worker ID list (encoded or numeric)"
        className={classes.workerIDList}
        source="worker_id_list"
        multiline
        rows={8}
      />
      <FormDataConsumer>
        {({ formData }) => (
          <Button
            className={classes.addWorkersButton}
            onClick={() => copyWorkers(formData)}
            variant="contained"
          >
            Add To Workers
          </Button>
        )}
      </FormDataConsumer>
    </>
  )
}

async function combineWorkers(dataProvider, formData) {
  const campaign_id = formData.id
  const workerIDs = formData.worker_id_list || ""
  const cworkers = formData.campaign_workers || []

  let ids = workerIDs.split("\n")
  ids = ids.map((id) => id.trim())
  ids = ids.filter((id) => !!id)

  if (!isNaN(ids[0]) && !isNaN(parseInt(ids[0]))) {
    ids = ids.map((id) => parseInt(id))
    const response = await dataProvider.post("/ids/convert", {
      id_type: "worker_id",
      decoded_ids: ids,
    })
    ids = response.data.map((r) => r.encoded_id)
  }

  const result = []
  for (const cworker of cworkers) {
    const idx = ids.indexOf(cworker.worker_id)
    if (idx < 0) {
      result.push(cworker)
      cworker.status = "unsent"
      ids = ids.filter((id) => id != cworker.worker_id)
    }
  }

  for (const id of ids) {
    const wresp = await dataProvider.getOne("workers", { id: id })
    result.push({
      campaign_id: campaign_id,
      worker_id: id,
      send_status: "unsent",
      worker: {
        id: id,
        address_txt: wresp.data.address_txt,
        first_name: wresp.data.first_name,
        last_name: wresp.data.last_name,
        mobile_number: wresp.data.mobile_number,
      },
    })
  }

  return result
}

const preventNull = (record) => {
  if (!record.job_id) {
    record = {
      ...record,
      job_id: null,
    }
  }
  return record
}

export const CampaignCreate = (props) => (
  <Create {...props} transform={preventNull}>
    <CampaignForm {...props} />
  </Create>
)

export const CampaignEdit = (props) => (
  <Edit {...props} transform={preventNull}>
    <CampaignForm {...props} />
  </Edit>
)

export const CampaignShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="type" />
      <TextField source="id" />
      <TextField label="Name" source="name" />
      <JobFlyerField source="job_id" />
      <TextField source="status" />
      <DateField source="time_created" showTime />
      <DateField source="time_published" showTime />

      <h3>Push Payload</h3>
      <TextField source="payload.title" />
      <TextField source="payload.body" />
      <TextField source="payload.anchor" />
      <UrlField source="payload.link" />
    </SimpleShowLayout>
  </Show>
)
