import React, { Fragment } from "react"
import PropTypes from "prop-types"

import {
  BooleanInput,
  BulkDeleteButton,
  Button,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ShowButton,
  TextField,
  TextInput,
  useDataProvider,
  useNotify,
  useRefresh,
  useUnselectAll,
} from "react-admin"

import { CustomListActions } from "list_actions"
import { ProjectInput, TaskStatusInput } from "fields"
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash"

const TaskFilter = (props) => (
  <Filter key="task_filter" {...props}>
    <TextInput alwaysOn label="Keyword" source="q" />
    <TextInput label="ID" source="id" />
    <TextInput alwaysOn label="Unique ID" source="unique_id" />
    <ProjectInput alwaysOn />
    <TaskStatusInput alwaysOn source="status" />
    <BooleanInput source="deleted" />
  </Filter>
)

const BulkTaskUndelete = ({ selectedIds }) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const unselectAll = useUnselectAll()
  const dataProvider = useDataProvider()

  const undeleteTasks = async () => {
    for (let id of selectedIds) {
      try {
        await dataProvider.post(`/tasks/${id}/undelete`)
      } catch (err) {
        notify("Task restore failed", "warning")
      }
    }
    const plural = selectedIds.length > 1
    notify(`${selectedIds.length} task${plural ? "s" : ""} restored`)
    unselectAll()
    refresh()
  }
  return (
    <Button
      label="Undelete Tasks(s)"
      disabled={!selectedIds || !selectedIds.length}
      onClick={undeleteTasks}
    >
      <RestoreFromTrashIcon />
    </Button>
  )
}

BulkTaskUndelete.propTypes = {
  selectedIds: PropTypes.array,
}

export const TaskList = (props) => {
  return (
    <List
      {...props}
      filters={<TaskFilter />}
      hasCreate={true}
      actions={<CustomListActions {...props} hasCreate={true} />}
    >
      <Datagrid
        bulkActionButtons={
          <Fragment>
            <BulkDeleteButton key="0" />,
            <BulkTaskUndelete key="1" />,
          </Fragment>
        }
      >
        <ReferenceField
          source="project_id"
          reference="projects"
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" />
        <TextField source="unique_id" label="Unique&nbsp;ID" />
        <TextField source="status" />
        <TextField source="responsible_party" />
        <DateField source="time_created" />

        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  )
}
