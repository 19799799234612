import React from "react"
import PropTypes from "prop-types"

import {
  EditButton,
  Error,
  Datagrid,
  DateField,
  Show,
  List,
  Loading,
  NumberField,
  ReferenceField,
  Tab,
  TabbedShowLayout,
  TextField,
  useGetList,
  useRecordContext,
} from "react-admin"

import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography"

import { JobMatchBulkActions } from "./add_to_campaign"
import { AuditList } from "audits"

const useStyles = makeStyles({
  inline: {
    display: "inline-flex",
    marginRight: "1rem",
  },
})

const Worker = () => {
  const record = useRecordContext()
  const lbl = `${record.first_name} ${record.last_name} ${record.mobile_number}`
  return <span>{lbl}</span>
}

const JobMatchList = ({ record, ...props }) => {
  return (
    <List
      {...props}
      hasList={false}
      hasEdit={false}
      hasShow={false}
      hasCreate={false}
      resource="jobmatches"
      bulkActionButtons={<JobMatchBulkActions jobID={record && record.id} />}
      filter={{ status: "Suggested", job_id: record && record.id }}
    >
      <Datagrid>
        <ReferenceField label="Worker" source="worker_id" reference="workers">
          <Worker />
        </ReferenceField>
        <TextField source="status" />
        <NumberField source="summary" label="Summary" />
        <NumberField source="scores.skills" label="Skill" sortable={false} />
        <NumberField source="scores.experience" label="Exp" sortable={false} />
        <NumberField source="scores.location" label="Loc" sortable={false} />
        <NumberField source="scores.pay" label="Pay" sortable={false} />
        <NumberField source="scores.pitch" label="Pitch" sortable={false} />
        <NumberField
          source="scores.certifications"
          label="Cert"
          sortable={false}
        />
        <NumberField
          source="scores.years_of_xp"
          label="YearsOfXP"
          sortable={false}
        />
      </Datagrid>
    </List>
  )
}

JobMatchList.propTypes = {
  record: PropTypes.object,
}

const JobApplicationList = ({ record, ...props }) => {
  return (
    <List
      {...props}
      hasList={false}
      hasEdit={true}
      hasShow={false}
      hasCreate={false}
      resource="jobapplications"
      filter={{ job_id: record && record.id }}
    >
      <Datagrid>
        <ReferenceField label="Worker" source="worker_id" reference="workers">
          <Worker />
        </ReferenceField>
        <TextField label="Invite" source="invite_contact_name" />
        <DateField source="time_created" showTime />
        <DateField source="time_updated" showTime />
        <TextField source="application_status" />
        <TextField source="worker_read_status" />
        <EditButton record={record} />
      </Datagrid>
    </List>
  )
}

JobApplicationList.propTypes = {
  record: PropTypes.object,
}

const NullableHirer = (props) => {
  if (!props.record || !props.record.company_id) {
    return null
  }

  return <Hirer {...props} />
}

NullableHirer.propTypes = {
  record: PropTypes.object,
}

NullableHirer.defaultProps = {
  addLabel: true,
}

const Hirer = (props) => {
  const { data, loading, error } = useGetList("workers", {
    pagination: { page: 1, perPage: 1 },
    filter: { company_id: props && props.record && props.record.company_id },
    sort: [],
  })

  if (loading) return <Loading />
  if (error) return <Error error={error} />

  if (data.length === 0) {
    return <Typography variant="body2">None found</Typography>
  }

  return <Worker record={data[0]} />
}

Hirer.propTypes = {
  record: PropTypes.object,
}

export const JobShow = (props) => {
  const styles = useStyles()

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <ReferenceField source="company_id" reference="companies">
            <TextField source="company_name" />
          </ReferenceField>
          <NullableHirer label="Hirer" />
          <TextField source="trade" />
          <TextField source="job_location" />
          <NumberField source="headcount" />
          <NumberField source="pay_low" className={styles.inline} />
          <NumberField source="pay_high" className={styles.inline} />
          <TextField source="job_location" />
        </Tab>
        <Tab label="Matches">
          <JobMatchList />
        </Tab>
        <Tab label="Applications">
          <JobApplicationList />
        </Tab>
        <Tab label="Audits">
          <AuditList
            source_table="job"
            hide_fields={["time_updated", "edits", "time_created"]}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
