import React from "react"

import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from "react-admin"

import { TimeInput, TimezoneInput } from "fields"

export const ProjectCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput
          fullWidth
          label="Company"
          source="company_id"
          reference="companies"
          sort={{ field: "company_name", order: "ASC" }}
          filterToQuery={(searchText) => ({ q: searchText })}
          allowEmpty={true}
        >
          <AutocompleteInput optionText="company_name" source="company_name" />
        </ReferenceInput>
        <TextInput source="name" fullWidth />
        <TimezoneInput source="timezone" />
        <TimeInput source="default_task_start" />
        <TimeInput source="default_task_end" />
      </SimpleForm>
    </Create>
  )
}
