import React, { useState } from "react"

import {
  // eslint-disable-next-line
  form,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material"

import { Title } from "react-admin"
import { IDConverter } from "./id_converter"
import { ProjectDashboard } from "./project_dashboard"

export const UtilityView = () => {
  const [utility, setUtility] = useState("id_convert")

  const handleChange = (e) => setUtility(e.target.value)

  return (
    <Card>
      <Title title="Utilities" />
      <CardContent>
        <form autoComplete="off">
          <FormControl>
            <InputLabel htmlFor="id-report">Utility</InputLabel>
            <Select
              value={utility}
              onChange={handleChange}
              inputProps={{
                name: "utility",
                id: "id-utility",
              }}
            >
              <MenuItem value={"id_convert"}>ID Converter</MenuItem>
              <MenuItem value={"dashboard"}>Project Dashboard</MenuItem>
            </Select>
          </FormControl>
        </form>
        {utility === "id_convert" ? <IDConverter /> : null}
        {utility === "dashboard" ? <ProjectDashboard /> : null}
      </CardContent>
    </Card>
  )
}
