import React, { useState } from "react"

import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  TextField,
} from "@mui/material"

import { makeStyles } from "@mui/styles"

import { Title, useNotify } from "react-admin"

import { dataProvider } from "data_provider"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "300px",
    display: "block",
  },
  inputControl: {
    minWidth: "300px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  label: {
    marginTop: "-12px",
  },
  buttonGroup: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1),
  },
}))

export const LoginGrant = () => {
  const [record, setRecord] = useState({
    name: "",
    firebase_uid: "",
    role: "admin",
  })
  const notify = useNotify()

  const classes = useStyles()

  async function onSave() {
    return dataProvider
      .grantRole(record.fbid, record.user_name, record.role)
      .then((resp) => {
        if (resp.status == 200) {
          notify("Role granted!")
        } else {
          notify("Grant failed", "warning")
        }
      })
      .catch((err) => {
        notify("Grant failed: " + err, "warning")
      })
  }

  return (
    <Card>
      <Title title="Grant roles to user" />
      <CardContent>
        <form>
          <FormControl className={classes.formControl}>
            <TextField
              id="fbid-input"
              label="Firebase ID"
              variant="outlined"
              value={record.fbid}
              onChange={(ev) =>
                setRecord({
                  ...record,
                  fbid: ev.target.value,
                })
              }
              className={classes.inputControl}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <TextField
              id="name-input"
              label="Name"
              variant="outlined"
              value={record.user_name}
              onChange={(ev) =>
                setRecord({ ...record, user_name: ev.target.value })
              }
              className={classes.inputControl}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel
              id="demo-simple-select-required-label"
              className={classes.label}
            >
              Role
            </InputLabel>
            <Select
              label="Role"
              id="role-input"
              variant="outlined"
              value={record.role}
              onChange={(ev) =>
                setRecord({
                  ...record,
                  role: ev.target.value,
                })
              }
              className={classes.inputControl}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="agent">Agent</MenuItem>
            </Select>
          </FormControl>

          <ButtonGroup
            onClick={onSave}
            color="primary"
            variant="contained"
            aria-label="outlined primary button group"
            className={classes.buttonGroup}
          >
            <Button>Grant Role</Button>
          </ButtonGroup>
        </form>
      </CardContent>
    </Card>
  )
}
