import React from "react"
import PropTypes from "prop-types"

import { Link } from "react-router-dom"

import AddIcon from "@mui/icons-material/Add"

import {
  BulkDeleteWithConfirmButton,
  Button,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  NumberField,
  SelectInput,
  ShowButton,
  ReferenceField,
  TextField,
} from "react-admin"

import { JobFlyerURLField, TradeInput } from "../fields"
import { CustomListActions } from "../list_actions"

const JobBulkActionButtons = (props) => {
  return (
    <>
      <BulkDeleteWithConfirmButton
        {...props}
        confirmContent="Are you sure you want to delete this job? It will cancel all job applications for this job!"
      />
    </>
  )
}

const JobFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Status"
      source="status"
      alwaysOn
      choices={[
        { id: "preview", name: "Preview" },
        { id: "open", name: "Open" },
        { id: "closed", name: "Closed" },
        { id: "canceled", name: "Canceled" },
      ]}
    />
    <SelectInput
      label="Type"
      source="type"
      alwaysOn
      choices={[
        { id: "joblet", name: "Joblet" },
        { id: "full_job", name: "Full job" },
      ]}
    />
    <TradeInput alwaysOn source="trade" />
  </Filter>
)

JobFilter.propTypes = {
  filterDefaultValues: PropTypes.object,
}

const MassCreateButton = ({ company_id }) => (
  <Button
    component={Link}
    to={{
      search: `?company_id=${company_id}`,
      pathname: "/jobs/masscreate",
    }}
    label="Bulk Create"
    onClick={(e) => e.stopPropagation()}
  >
    <AddIcon />
  </Button>
)

MassCreateButton.propTypes = {
  company_id: PropTypes.string,
}

const getListButtons = (company_id) => {
  const buttons = []
  if (company_id) {
    buttons.push(
      <Button
        key="custom-1"
        component={Link}
        to={{
          pathname: "/jobs/create",
          search: `?company_id=${company_id}`,
        }}
        label="Create"
        color="primary"
      >
        <AddIcon />
      </Button>
    )
    buttons.push(<MassCreateButton company_id={company_id} key="custom-0" />)
  }

  return buttons
}

export const JobList = (props) => {
  return (
    <List
      {...props}
      filters={<JobFilter />}
      bulkActionButtons={<JobBulkActionButtons />}
      actions={
        <CustomListActions
          hasCreate={!props.company_id}
          hasExport
          addlButtons={getListButtons(props.company_id)}
        />
      }
      sort={{ field: "time_created", order: "DESC" }}
    >
      <Datagrid>
        <TextField source="status" />
        <ReferenceField
          source="company_id"
          reference="companies"
          sortable={false}
        >
          <TextField source="company_name" />
        </ReferenceField>
        <TextField source="trade" />
        <NumberField source="headcount" />
        <NumberField source="pay_low" />
        <NumberField source="pay_high" />
        <TextField source="job_location" />
        <JobFlyerURLField source="id" />
        <DateField source="time_created" />
        <ShowButton resource="jobs" />
        <EditButton resource="jobs" />
      </Datagrid>
    </List>
  )
}

JobList.propTypes = {
  company_id: PropTypes.string,
}
