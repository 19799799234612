import React from "react"

import {
  DateField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin"

import { makeStyles } from "@mui/styles"
import { TaskAssignment } from "./task_assignment"
import { AuditList } from "audits"

const useStyles = makeStyles({
  inlineLeft: {
    display: "inline-flex",
    marginRight: "20px",
    width: "calc(50% - 10px)",
  },
  inlineRight: {
    display: "inline-flex",
    width: "calc(50% - 10px)",
  },
  fullwidth: {
    width: "100%",
  },
})

export const TaskShow = (props) => {
  const classes = useStyles()
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Assignment">
          <TaskAssignment />
        </Tab>
        <Tab label="Basic">
          <ReferenceField
            fullWidth
            label="Project"
            source="project_id"
            reference="projects"
            sort={{ field: "name", order: "ASC" }}
            filterToQuery={(searchText) => ({ name: searchText })}
            allowEmpty={true}
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField fullWidth source="unique_id" label="Unique ID" />
          <TextField fullWidth source="name" label="Task Name" />
          <TextField fullWidth multiline source="description" />
          <TextField fullWidth source="responsible_party" />
          <TextField fullWidth source="location" />
          <TextField fullWidth source="status" />
          <DateField
            source="sched_start_date"
            classes={{ root: classes.fullwidth }}
            formClassName={classes.inlineLeft}
          />
          <DateField
            source="sched_end_date"
            classes={{ root: classes.fullwidth }}
            formClassName={classes.inlineRight}
          />
          <TextField
            fullWidth
            source="groups[0].group.ext_chat_id"
            label="Group Chat ID"
          />
        </Tab>
        <Tab label="Audits">
          <AuditList
            source_table="task"
            hide_fields={["status_history", "time_created", "time_updated"]}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
