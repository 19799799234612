import React, { Fragment, useState, useEffect } from "react"
import PropTypes from "prop-types"

import AddIcon from "@mui/icons-material/Add"

import {
  Button as MuiButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Select,
  TextField as MuiTextField,
  MenuItem,
  DialogActions,
} from "@mui/material"

import { Button, useNotify, useRefresh, useUnselectAll } from "react-admin"

import { dataProvider } from "../data_provider"

export const JobMatchBulkActions = ({ selectedIds, jobID }) => {
  const [open, setOpen] = useState(false)
  const [name, setName] = useState("")
  const [loading, setLoading] = useState(false)
  const [campaigns, setCampaigns] = useState(null)
  const [campaignId, setCampaignId] = useState("new")
  const refresh = useRefresh()
  const unselectAll = useUnselectAll("jobmatches")
  const notify = useNotify()

  useEffect(() => {
    if (!loading && campaigns === null) {
      setLoading(true)
      dataProvider
        .getList("campaigns", {
          filter: { job_id: jobID },
          pagination: { page: 1, perPage: 100 },
          sort: ["id", "ASC"],
        })
        .then(({ data }) => setCampaigns(data))
        .finally(() => setLoading(false))
    }
  }, [jobID])

  const handleDialogClose = () => {
    refresh()
    unselectAll()
    setCampaigns(null)
  }

  const handleConfirm = async () => {
    let matches = await dataProvider.getMany("jobmatches", { ids: selectedIds })
    let workers = matches.data.map((match) => {
      return {
        worker_id: match.worker_id,
      }
    })
    if (campaignId === "new") {
      try {
        await dataProvider.create("campaigns", {
          data: {
            name: name,
            job_id: jobID,
            campaign_workers: workers,
          },
        })
        notify("Created campaign")
      } catch (err) {
        notify("Error creating campaign", "error")
      }
    } else {
      try {
        await dataProvider.addWorkersToCampaign(campaignId, workers)
        notify("Added workers to campaign")
      } catch (err) {
        notify("Error adding workers to campaign", "error")
      }
    }
    setOpen(false)
    handleDialogClose()
  }

  const getOptions = () => {
    let opts = [
      <MenuItem key="campaign-new" value={"new"}>
        New Campaign
      </MenuItem>,
    ]
    if (campaigns) {
      for (let c of campaigns) {
        opts.push(
          <MenuItem key={`campaign-${c.id}`} value={c.id}>
            {c.name}
          </MenuItem>
        )
      }
    }
    return opts
  }

  return (
    <Fragment>
      <Button
        onClick={() => {
          setOpen(true)
        }}
        label="Add to Campaign"
      >
        <AddIcon />
      </Button>
      <Dialog
        fullWidth
        open={open}
        onClose={handleDialogClose}
        aria-label="Create post"
      >
        <DialogTitle>Add To Campaign</DialogTitle>
        <DialogContent>
          <DialogContentText gutterBottom>
            Add {selectedIds.length}{" "}
            {selectedIds.length === 1 ? "worker" : "workers"} to:
          </DialogContentText>
          <Select
            fullWidth
            value={campaignId}
            onChange={(event) => setCampaignId(event.target.value)}
          >
            {getOptions()}
          </Select>
          {campaignId === "new" ? (
            <p>
              <MuiTextField
                variant="filled"
                required
                value={name}
                label="Campaign name"
                fullWidth
                margin="dense"
                onChange={(evt) => setName(evt.target.value)}
              />
            </p>
          ) : null}
        </DialogContent>
        <DialogActions>
          <MuiButton label="ra.action.cancel" onClick={handleDialogClose}>
            Cancel
          </MuiButton>
          <MuiButton
            disabled={campaignId === "new" && !name.trim().length}
            onClick={handleConfirm}
            color="primary"
          >
            Add to campaign
          </MuiButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

JobMatchBulkActions.propTypes = {
  selectedIds: PropTypes.array,
  jobID: PropTypes.number,
}
