import React from "react"
import PropTypes from "prop-types"
import Config from "./config"

import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  Filter,
  List,
  ReferenceField,
  SelectInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useRecordContext,
} from "react-admin"
import { CustomListActions } from "./list_actions"

const WorkerCardField = ({ source }) => {
  const record = useRecordContext()
  const name = record[source]
  return (
    <a
      href={`${Config.jbURL}/workercard/${record[source]}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {name}
    </a>
  )
}

WorkerCardField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
}

const CertificationFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="VerificationStatus"
      source="verification_status"
      alwaysOn
    />
    <TextInput label="Status" source="status" alwaysOn />
  </Filter>
)

export const CertificationList = ({ hasEdit, hasList, ...props }) => (
  <List
    {...props}
    hasList={hasList}
    hasEdit={hasEdit}
    actions={<CustomListActions {...props} maxResults={10000} />}
    filterDefaultValues={{ verification_status: "queued", status: "A" }}
    filters={<CertificationFilter />}
  >
    <Datagrid>
      <WorkerCardField label="card" source="worker_id" sortable={false} />
      <TextField label="first" source="worker.first_name" sortable={false} />
      <TextField label="last" source="worker.last_name" sortable={false} />
      <TextField label="certificate_name" source="certificate_name" />
      <DateField source="issued_date" showTime />
      <DateField source="expiration_date" showTime />
      <BooleanField source="is_verified" />
      <TextField label="status" source="verification_status" />
      <DateField source="time_created" showTime />
      <EditButton />
    </Datagrid>
  </List>
)

CertificationList.propTypes = {
  hasEdit: PropTypes.bool,
  hasList: PropTypes.bool,
}

export const CertificationEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <WorkerCardField label="Card" source="worker_id" />
        <ReferenceField source="worker_id" reference="workers">
          <SimpleShowLayout>
            <DateField source="time_created" showTime />
            <TextField source="first_name" />
            <TextField source="last_name" />
            <TextField source="status" />
            <TextField source="mobile_number" />
            <TextField source="address_txt" />
            <TextField source="trade" />
            <TextField source="union_id" />
            <TextField source="union_name" />
            <TextField source="years_of_xp" />
            <TextField source="hireme_pitch" />
            <TextField source="resume_url" />
          </SimpleShowLayout>
        </ReferenceField>
        <TextInput disabled source="id" />
        <TextInput source="issued_id" />
        <TextInput source="certificate_name" />
        <TextInput source="institute_name" />
        <TextInput source="jurisdiction" />
        <DateInput source="issued_date" />
        <DateInput source="expiration_date" />
        <BooleanInput source="is_verified" />
        <SelectInput
          source="verification_status"
          choices={[
            { id: "created", name: "Created" },
            { id: "queued", name: "Queued" },
            { id: "completed", name: "Completed" },
          ]}
        />
      </SimpleForm>
    </Edit>
  )
}
